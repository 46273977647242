<template>
  <div class="menu">
    <button :style="{ width }">
      <span>{{ options[selected] }}</span>
      <chevron />
    </button>
    <card :style="{ width }">
      <button
        v-for="option in options.filter((_, i) => i !== selected)"
        :key="option"
        @click="select(option)"
      >
        {{ option }}
      </button>
      <p v-if="options.length === 1">No option available</p>
    </card>
  </div>
</template>
<script>
import Chevron from "@/assets/chevron.svg";
import Card from "@/components/base/Card";

export default {
  name: "drop-down",
  components: { Chevron, Card },
  emits: ["update:modelValue"],
  props: {
    options: {
      type: Array,
      required: true,
      validator(value) {
        return (
          value.length > 0 &&
          !value.some((option) => typeof option !== "string")
        );
      },
    },
    modelValue: {
      type: Number,
      required: false,
      default: 0,
    },
    width: {
      type: String,
      required: false,
      default: "23ch",
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.selected = value;
      },
    },
  },
  methods: {
    select(option) {
      this.selected = this.options.findIndex((o) => o === option);
      this.$emit("update:modelValue", this.selected);
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
svg,
button {
  width: 1.4rem;
  height: 1.4rem;
}

button {
  padding: 0;
}

.menu {
  position: relative;

  height: fit-content;

  button {
    display: flex;

    font-size: 1rem;

    width: 100%;
  }

  :first-child {
    margin: auto 0.5ch auto auto;
    height: fit-content;
  }

  svg {
    transform: rotate(90deg);
  }

  > :last-child {
    margin: 0;
    padding: var(--half-padding) var(--content-padding);
    padding-left: 0;

    position: absolute;
    left: -0.5rem;
    top: 2.75rem;

    display: block;

    z-index: 1;

    &:before {
      content: " ";

      position: absolute;
      top: -1rem;

      border: solid 0.5rem;
      border-color: transparent transparent var(--dark-blue) transparent;
    }

    button,
    p {
      width: 100%;
      text-align: right;
      font-size: 1rem;
      display: block;
    }

    p {
      font-weight: 300;
      font-style: italic;
      color: hsl(0, 0%, 37%);
    }

    > * {
      padding: var(--half-padding) 0;
      height: fit-content;
    }

    transform: rotateX(-90deg) translate(0, -45%);
  }

  &:focus-within > :last-child {
    transform: none;
  }
}
</style>
