<template>
  <div class="dashboard">
    <div>
      <slot name="first" />
    </div>
    <div>
      <slot name="second" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DynamicGrid",
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex: auto;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 100%;
}

div {
  width: 85ch;
  max-width: 100%;

  > .card {
    width: calc(100% - 2 * var(--content-padding));
  }
}
</style>
