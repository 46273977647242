<template>
  <div class="controls">
    <div class="filter">
      <action-element
        v-for="(filter, i) in filtering"
        :key="filter.field"
        class="action"
      >
        <drop-down
          :model-value="currentIndex(filter.field)"
          :options="alternativeOptions(filter.field)"
          class="dropdown"
          @update:model-value="filter.field = mapIndex(filter.field, $event)"
          :width="dropDownWidth"
        />
        <drop-down
          v-if="numberField(filter.field)"
          v-model="filter.comparator"
          :options="getOperators(filter.field)"
          class="dropdown"
          width="13ch"
        />
        <drop-down
          v-else-if="dateField(filter.field)"
          v-model="filter.comparator"
          :options="['contains', 'before', 'after']"
          class="dropdown"
          width="11ch"
        />
        <content-editable
          v-model="filter.query"
          :class="{
            input: true,
            placeholder: filter.query.length === 0,
            error: faultyInput(filter),
          }"
          :noNL="true"
          :placeholder="fields[filter.field].placeholder"
          tag="p"
        />
        <span class="remove">
          <button @click="filtering.splice(i, 1)"><remove /></button>
        </span>
      </action-element>
      <action-element v-if="filtering.length !== fields.length" class="action">
        <button @click="addFilter">
          <filter-icon /><span>Add Filter</span>
        </button>
      </action-element>
    </div>
    <action-element class="action">
      <drop-down
        v-model="sort.field"
        :options="fieldLabels"
        :width="dropDownWidth"
      />
      <button @click="sort.ascending = !sort.ascending">
        <sort :class="{ ascending: sorting.ascending }" />
      </button>
    </action-element>
  </div>
</template>

<script>
import ActionElement from "@/components/base/ActionElement";
import Filter from "@/assets/filter.svg";
import Sort from "@/assets/sort.svg";
import DropDown from "@/components/base/DropDown";
import ContentEditable from "vue-contenteditable/";
import Remove from "@/assets/remove.svg";
import queryToNumber from "@/components/base/queryToNumber";
import { debounce } from "vue-debounce";

const fieldsProp = {
  type: Array,
  required: true,
  validator(value) {
    return (
      value.length > 0 &&
      !value.some(
        (option) =>
          option.label === undefined || typeof option.placeholder !== "string",
      )
    );
  },
};

const sortingProp = {
  type: Object,
  required: false,
  validator(value) {
    return (
      typeof value.ascending === "boolean" && Number.isInteger(value.field)
    );
  },
  default() {
    return {
      ascending: true,
      field: 0,
    };
  },
};

const filtersProp = {
  type: Array,
  required: false,
  validator(value) {
    return !value.some(
      (filter) => !Number.isInteger(filter.field) && filter.query !== undefined,
    );
  },
  default: () => [],
};

export default {
  name: "ListControls",
  components: {
    DropDown,
    ActionElement,
    Sort,
    FilterIcon: Filter,
    ContentEditable,
    Remove,
  },
  emits: ["update:sorting", "update:filters"],
  props: {
    fields: fieldsProp,
    sorting: sortingProp,
    filters: filtersProp,
  },
  mixins: [queryToNumber],
  created() {
    Object.keys(this.$route.query).forEach((key) => {
      const field = this.fieldLabels.findIndex((label) => {
        return key === this.getQueryField(label);
      });

      if (field > -1) {
        this.filtering.push({
          field,
          query: this.$route.query[key],
          comparator: Number(this.$route.query[`${key}_comparator`] ?? 0),
        });
      }
    });
  },
  computed: {
    fieldLabels() {
      return this.fields.map((field) => field.label);
    },
    usedFilterFields() {
      let fields = new Set();
      this.filtering.forEach((filter) => fields.add(filter.field));

      return fields;
    },
    dropDownWidth() {
      let length =
        Math.max(
          ...this.fields.map((field) => {
            return field.label.length;
          }),
        ) + 2;

      return `${length}ch`;
    },
    updateURL() {
  return debounce((filters) => {
    const route = { path: this.$route.path, query: {} };

    filters.forEach((filter) => {
      const field = this.getQueryField(this.fields[filter.field].label);

      if (filter.query.length > 0) {
        let query = filter.query;
        if (typeof query === 'string') {
          query = query.replace(/\n/g, '');
        }
        route.query[field] = query;

        if ((filter.comparator ?? 0) !== 0) {
          route.query[`${field}_comparator`] = filter.comparator;
        }
      }
    });

    this.$router.replace(route);
  }, 750);
},

  },
  watch: {
    sorting: {
      immediate: true,
      handler(value) {
        this.sort = value;
      },
    },
    sort: {
      deep: true,
      handler(value) {
        this.$emit("update:sorting", value);
      },
    },
    filters: {
      immediate: true,
      handler(value) {
        this.filtering = value;
      },
    },
    filtering: {
      deep: true,
      handler(value) {
        this.updateURL(value);
        this.$emit("update:filters", value);
      },
    },
  },
  methods: {
    addFilter() {
      for (let i = 0; i < this.fields.length; ++i) {
        if (!this.usedFilterFields.has(i)) {
          this.filtering.push({ field: i, query: "" });
          return;
        }
      }
    },
    alternativeOptions(usedField) {
      return this.fieldLabels.filter(
        (_, i) => i === usedField || !this.usedFilterFields.has(i),
      );
    },
    currentIndex(usedField) {
      let current = this.alternativeOptions(usedField);

      return current.findIndex(
        (label) => this.fieldLabels[usedField] === label,
      );
    },
    mapIndex(usedField, currentIndex) {
      let current = this.alternativeOptions(usedField);

      return this.fieldLabels.findIndex(
        (label) => label === current[currentIndex],
      );
    },
    numberField(usedField) {
      return this.fields[usedField].type === Number;
    },
    dateField(usedField) {
      return this.fields[usedField].type === Date;
    },
    getOperators(field) {
      return (
        this.fields[field].optionLabels ?? [
          "contains",
          "less than",
          "more than",
        ]
      );
    },
    faultyInput(filter) {
      let validator = this.fields[filter.field].validator;

      if (validator) {
        return validator(filter.query);
      }

      if (this.numberField(filter.field)) {
        return Number.isNaN(this.queryToNumber(filter.query));
      } else if (this.dateField(filter.field)) {
        // noinspection JSUnresolvedVariable
        return (
          (filter?.comparator ?? 0) !== 0 &&
          Number.isNaN(Date.parse(filter.query))
        );
      }

      return false;
    },
    getQueryField(label) {
      return label.toLocaleLowerCase().replaceAll(" ", "_");
    },
  },
  data() {
    return {
      filtering: [],
      sort: {
        ascending: true,
        field: 3,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.input {
  font-size: 1rem;
  text-align: left;
  word-break: break-word;

  outline: none;

  min-width: 5ch;
  width: fit-content;

  max-width: 100%;

  transition: none;
}

svg,
button {
  width: 1.4rem;
  height: 1.4rem;
}

button {
  display: flex;
  width: fit-content;
  max-width: 100%;

  span {
    font-size: 1rem;

    margin: auto var(--half-padding) auto;
    height: fit-content;
  }
}

select {
  background: none;
  border: none;
  font-size: 1rem;

  outline: none;

  appearance: none;
}

.controls {
  --half-padding: calc(var(--content-padding) / 2);

  display: flex;

  @media (max-width: 90ch) {
    flex-direction: column-reverse;

    > :last-child {
      margin-left: auto;
    }
  }

  > :first-child {
    flex: 1;

    display: flex;
    flex-wrap: wrap;

    margin-right: calc(var(--content-padding) * 1.5);
    margin-bottom: calc(var(--content-padding) * 1.5);

    > * {
      margin-right: var(--half-padding);
      margin-bottom: var(--half-padding);
      margin-top: var(--half-padding);
    }
  }

  margin-bottom: var(--content-padding);
}

.action {
  margin-bottom: var(--content-padding);

  > *:not(:first-child) {
    padding-left: var(--half-padding);
    margin-left: var(--half-padding);

    &[contenteditable="true"] {
      padding-left: var(--content-padding);
      padding-right: var(--half-padding);
    }

    border-left: 1px black solid;
  }

  .remove {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

button {
  padding: 0;
}

.dropdown {
  margin: auto;
}

.placeholder:before {
  content: attr(placeholder);
}

.error {
  color: var(--red);
}
</style>

<style>
.action * {
  transition: 225ms ease-in-out;
}

.ascending :last-child {
  transform: rotateX(-180deg) translate(0, -24px);
}
</style>
