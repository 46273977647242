<template>
  <card class="card">
    <h2>Equipment Overview</h2>
    <table :data="entries">
      <thead>
        <tr>
          <th>Unit</th>
          <th>Count</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in entries" :key="row.id">
          <td>{{ row.unit }}</td>
          <td>
            <span :class="{ placeholder: row.count === undefined }">
              {{ row.count ?? "Unknown" }}
            </span>
          </td>
          <td><action-link :page="row.action" /></td>
        </tr>
      </tbody>
    </table>
  </card>
</template>

<script>
import Card from "@/components/base/Card";
import Link from "@/assets/link.svg";
import ShoppingCart from "@/assets/shoppingCart.svg";
import ActionLink from "@/components/base/ActionLink";
import { markRaw } from "vue";

export default {
  name: "EquipmentOverview",
  components: { ActionLink, Card },
  computed: {
    entries() {
      return [
        {
          unit: "Active Devices",
          count: Object.keys(this.$store.state.devices).length,
          action: markRaw({
            page: { name: "Devices" },
            text: "View all devices",
            icon: Link,
          }),
        },
        {
          unit: "Remaining consumables",
          count: undefined,
          action: markRaw({
            href: "https://example.com",
            text: "Order consumables",
            icon: ShoppingCart,
          }),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@use "/src/components/base/table";

th:nth-child(1) {
  width: 45%;
}

th:nth-child(3) {
  width: 61.5%;
}

th:nth-child(3),
td:nth-child(3) {
  padding-right: 0;
}

.action {
  margin-left: auto;
}
</style>
