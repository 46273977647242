export default {
  methods: {
    queryToNumber(query) {
      let number = query.slice();

      number = number.replaceAll(/ +/g, "");
      number = number.replaceAll(/,/g, ".");

      return Number(number);
    },
  },
};
