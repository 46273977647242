<template>
  <navigation-bar />
  <div class="container content">
    <dynamic-grid>
      <template #first>
        <card class="card">
          <h2>Information</h2>
    <!-- Display Mode -->
    <div v-if="!isEditing">
        <split-data v-model:data="info" />
        <button class="btn btn-edit" @click="startEditing">Edit Information</button>
          </div>

    <!-- Edit Mode -->
    <div v-else>
        <!-- Assuming SplitData component can handle input fields for editing -->
        <split-data v-model:data="tempInfo" />
        <button class="btn btn-save" @click="saveChanges">Save</button>
        <button class="btn btn-cancel" @click="cancelEditing">Cancel</button>
          </div>        </card>
        <card class="card">
          <h2>Device Status</h2>
          <split-data :data="status" />
          <p v-if="statusTime">Diagnostics report time: {{ statusTime }}</p>
        </card>
      </template>
      <template #second>
        <recent-results class="results" :device="id" v-if="!device.hidden" />
      </template>
    </dynamic-grid>
    <span v-if="initialized">
      <action-element
        class="action admin"
        v-if="isAdmin"
        @click="toggleVisibility"
      >
        <visible v-if="device.hidden" />
        <hidden v-else />
        <div>{{ device.hidden ? "Include results" : "Exclude results" }}</div>
      </action-element>
      <action-element class="action" @click="unlink" v-else>
        <remove />
        <div>Unlink this device</div>
      </action-element>
    </span>
  </div>
</template>

<script>
import DynamicGrid from "@/components/base/DynamicGrid";
import Card from "@/components/base/Card";
import SplitData from "@/components/base/SplitData";
import RecentResults from "@/components/dashboard/RecentResults";
import ActionElement from "@/components/base/ActionElement";
import Remove from "@/assets/linkOff.svg";
import Hidden from "@/assets/hidden.svg";
import Visible from "@/assets/visible.svg";
import NavigationBar from "@/components/NavigationBar";
import { init, userAvailable } from "@/store";

import { debounce } from "vue-debounce";

export default {
  name: "Device",
  components: {
    NavigationBar,
    ActionElement,
    RecentResults,
    Card,
    SplitData,
    DynamicGrid,
    Remove,
    Hidden,
    Visible,
  },
  async created() {
    await userAvailable();
    await init;

    this.initialized = true;

    let linked =
      this.$store.state.config?.devices?.some((id) => id === this.id) ?? false;

    if (!linked && !this.$store.state.isAdmin) {
      // noinspection JSCheckFunctionSignatures
      await this.$router.replace({
        name: "Link",
        query: { identifier: this.id },
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    device() {
      return (this.$store.state?.devices ?? {})[this.id] ?? {};
    },
    updateInfo() {
      return debounce((changes) => {
        if (this.device.info === undefined) {
          console.log('uSING SET');

          this.device.ref?.set(changes);
        } else {
          this.device.ref?.update(changes);
          console.log('uSING UPDATE');

        }
      }, 500);
    },
    info: {
      get() {
        const device = this.device;

        return [
          {
            label: "Name",
            value: this.name ?? "",
            editable: this.isEditing,
            placeholder: "Device name",
          },
          { label: "Identifier", value: this.id },
          { label: "Bluetooth MAC-Address", value: device?.macAddress ?? "" },
          {
            label: "Operator",
            value: this.operator ?? "",
            editable: this.isEditing,
            placeholder: "Full name",
          },
          {
            label: "Location",
            value: this.location ?? "",
            editable: this.isEditing,
            placeholder: "City, ZIP code, Country",
          },
        ];
      },

     /* set(info) {
        this.name = info[0].value;
        this.operator = info[3].value;
        this.location = info[4].value;

        const changes = {
          name: this.name,
          operator: this.operator,
          location: this.location,
        };

        // noinspection JSCheckFunctionSignatures
        this.updateInfo(changes);
      },*/
    },
        status() {
      const status = this.device.info ?? {};

      const focus = status.averageFocusingPosition?.toString() ?? "";
      const testCount = status.deviceTestCount?.toString() ?? "";
      const temperature =
        status.temperature !== undefined ? `${status.temperature}°C` : "";

      return [
        { label: "Battery level", value: `${status.batteryLevel ?? 0}%` },
        { label: "Internet connection", value: status.internet ?? "" },
        { label: "Defogger", value: status.defogger ?? "" },
        { label: "Incubator", value: status.incubator ?? "" },
        { label: "Servo", value: status.servo ?? "" },
        {
          label: "Test count",
          value: testCount,
        },
        {
          label: "Average focusing Postion",
          value: focus,
        },
        { label: "Temperature", value: temperature },
        { label: "Software version", value: status.softwareVersion ?? "" },
        { label: "Firmware version", value: status.firmwareVersion ?? "" },
      ];
    },
    statusTime() {
      let time = this.device.info?.diagnosticTime;
      return time ? new Date(time.seconds * 1000).toLocaleString() : undefined;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    hidden() {
      return this.device.hidden;
    },
  },
  watch: {
  device: {
    immediate: true,
    handler(device) {
      if (device && device.info) {
        // If device.info.name is defined, update the local name. Otherwise, keep the current value.
        this.name = device.info.name !== undefined ? device.info.name : this.name;

        // Similarly for operator
        this.operator = device.info.operator !== undefined ? device.info.operator : this.operator;

        // And for location
        this.location = device.info.location !== undefined ? device.info.location : this.location;
      }
    },
  },
},
  methods: {
    unlink() {
      this.$store.dispatch("unlinkDevice", this.id);

      this.$router.go(-1);
    },
    toggleVisibility() {
      if (this.device.hidden) {
        this.$store.dispatch("showDevice", this.id);
      } else {
        this.$store.dispatch("hideDevice", this.id);
      }
    },
    startEditing() {
      
      this.isEditing = true;
      this.tempInfo = [...this.info];
    },

    saveChanges() {
        // Extract the values from tempInfo or whichever temporary storage you're using
        this.name = this.tempInfo[0].value;
        this.operator = this.tempInfo[3].value;
        this.location = this.tempInfo[4].value;

        // Now, you can update any other data or call methods as you did in the setter
        const changes = {
            name: this.name,
            operator: this.operator,
            location: this.location,
        };
        this.updateInfo(changes);
        this.isEditing = false;
    },

    cancelEditing() {
      this.isEditing = false;
    }
  },
  data() {
    return {
      name: "",
      operator: "",
      location: "",
      initialized: false,
      isEditing: false, // New data property for edit mode
      tempInfo: {}      // New data property for temporary changes
    };
  },
};
</script>

<style lang="scss" scoped>
.container,
.container > :first-child {
  width: 100%;
}

.card:not(.results):not(.action) {
  padding: var(--content-padding) calc(2 * var(--content-padding))
    calc(2 * var(--content-padding));

  * {
    padding: var(--content-padding) 0;
  }

  &:nth-child(2) {
    p {
      text-align: right;

      font-size: 0.9rem;
      font-weight: 300;
    }
  }
}
.btn {
  
    padding: 10px 10px !important;  // Increased horizontal padding
    border: none;
    border-radius: 5px;
    font-size: 12px;  // Set font size
    letter-spacing: 0.5px;  // Slightly spaced letters
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  // Light shadow
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        opacity: 0.8;
        transform: translateY(-2px);  // Slight lift on hover
    }

    &:active {
        transform: translateY(0);  // Reset position when active
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);  // Reduce shadow when pressed
    }

    &-edit {
        background-color: #01274f;
        color: white;
        margin-right: 10px;  // Space between buttons
    }

    &-save {
        background-color: #02612a;
        color: white;
        margin-right: 10px;
    }

    &-cancel {
        background-color: #d86b76;
        color: white;
    }
}
.action {
  display: flex;
  justify-content: center;
  margin: calc(5 * var(--content-padding)) auto;

  &:not(.admin) {
    color: var(--red);

    svg {
      fill: var(--red);
    }
  }

  svg {
    margin-right: calc(var(--content-padding) / 2);
    height: 1.4rem;
  }

  div {
    line-height: 1rem;
    padding-bottom: 0.1rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
