<template>
  <navigation-bar />
  <entry-creation-container
    v-if="initialized"
    v-model="input"
    :action="action"
    :entry="entry"
    :error="errorMessage"
    :device="{ info: device, id: (this.device ?? [])[1]?.value }"
  />
</template>

<script>
import EntryCreationContainer from "@/components/base/DeviceForm";
import NavigationBar from "@/components/NavigationBar";
import { db, init } from "@/store";
import { markRaw } from "vue";
import Add from "@/assets/add.svg";
import { debounce } from "vue-debounce";

export default {
  name: "AddDevice",
  components: { EntryCreationContainer, NavigationBar },
  async created() {
    await init;
    this.initialized = true;

    if (!this.$store.state.isAdmin) {
      await this.$router.replace({ name: "Link" });
    }
  },
  computed: {
    errorMessage() {
      if (this.macAddress.length < 17) {
        return this.input.trim().length !== 0 ? "MAC-Address incomplete" : "";
      } else if (/[^A-F:\d]/i.test(this.macAddress)) {
        return "MAC-Address contains invalid characters";
      } else if (this.macAddress.length > 17) {
        return "MAC-Address is too long";
      } else if (this.device) {
        return "Device already added";
      }

      return "";
    },
    checkDevice() {
      return debounce(async () => {
        if (this.errorMessage === "" && this.macAddress.trim() !== "") {
          const result = await db
            .collection("deviceIdentifiers")
            .where("macAddress", "==", this.macAddress)
            .get();

          if (this.errorMessage === "" && this.macAddress.trim() !== "") {
            if (result.docs.length !== 0) {
              let device = this.$store.state.devices[result.docs[0].id];

              this.device = [
                { label: "Name", value: device.info?.name ?? "" },
                { label: "Identifier", value: result.docs[0].id },
                {
                  label: "Bluetooth MAC-Address",
                  value: device.macAddress ?? "",
                },
                { label: "Operator", value: device.info?.operator ?? "" },
                { label: "Location", value: device.info?.location ?? "" },
                {
                  label: "Test count",
                  value: device.info?.deviceTestCount?.toString() ?? "",
                },
              ];
            } else {
              this.device = null;
            }
          } else {
            this.device = undefined;
          }
        } else {
          this.device = undefined;
        }
      }, 500);
    },
    action() {
      let enabled = this.errorMessage === "";
      enabled &= (this.macAddress?.length ?? 0) !== 0;
      enabled &= this.device === null;

      return {
        icon: markRaw(Add),
        text: "Add this this device",
        action: markRaw(this.addDevice),
        enabled: Boolean(enabled),
      };
    },
    macAddress() {
      let value = "";
      this.input
        .trim()
        .split(":")
        .forEach((part) => {
          while (part.length === 0) {
            part = " ";
          }

          value += part;
        });

      value = value.toUpperCase();

      const parts = [];

      while (value.length !== 0 && parts.length !== 6) {
        parts.push(value.substring(0, 2));
        value = value.slice(2);
      }

      return (parts.join(":") + value).trim();
    },
  },
  watch: {
    input() {
      this.device = undefined;
      this.checkDevice();
    },
  },
  methods: {
    addDevice() {
      const doc = db.collection("deviceIdentifiers").doc();

      doc.set({
        identifier: doc.id,
        info: db.collection("devices").doc(this.macAddress),
        macAddress: this.macAddress,
      });

      this.$router.push({ path: `/device/${doc.id}` });
    },
  },
  data() {
    return {
      initialized: false,
      input: "",
      device: undefined,
      entry: markRaw({
        title: "Add a new device",
        label: "Device MAC-address",
        placeholder: "XX:XX:XX:XX:XX:XX",
      }),
    };
  },
};
</script>
