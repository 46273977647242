<template>
  <div class="container content">
    <div>
      <card class="card">
        <h2>{{ entry.title }}</h2>
        <text-field
          class="input"
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
          :placeholder="entry.placeholder"
          :label="entry.label"
          :multiline="false"
        />
        <p v-if="error.length > 0" class="error">{{ error }}</p>
      </card>
      <card class="card info" v-if="(device?.info?.length ?? 0) > 0">
        <h2>Device information</h2>
        <split-data v-if="device" :data="device.info" />
        <action-link
          v-if="device.id"
          :page="{
            page: { path: `/device/${device.id}` },
            text: 'View all details',
            icon: details,
          }"
        />
      </card>
      <action-element
        v-if="action.enabled"
        class="action"
        @click="action.action"
      >
        <component :is="action.icon" />
        {{ action.text }}
      </action-element>
    </div>
  </div>
</template>

<script>
import ActionElement from "@/components/base/ActionElement";
import Card from "@/components/base/Card";
import TextField from "@/components/base/TextField";
import SplitData from "@/components/base/SplitData";
import ActionLink from "@/components/base/ActionLink";
import { markRaw } from "vue";
import Details from "@/assets/assessment.svg";

export default {
  name: "EntryCreationContainer",
  components: { ActionLink, ActionElement, Card, TextField, SplitData },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    entry: {
      type: Object,
      required: true,
      validator(value) {
        return (
          typeof value?.title === "string" &&
          typeof value?.label === "string" &&
          typeof value?.placeholder === "string"
        );
      },
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    action: {
      type: Object,
      required: true,
      validator(value) {
        return (
          typeof value?.enabled === "boolean" &&
          typeof value?.text === "string" &&
          typeof value?.icon === "object" &&
          typeof value?.action === "function"
        );
      },
    },
    device: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      details: markRaw(Details),
    };
  },
};
</script>

<style scoped lang="scss">
svg {
  height: 1.4rem;
  width: 1.4rem;

  margin-right: calc(var(--content-padding) / 2);
}

.info {
  h2 {
    padding-bottom: var(--content-padding);
  }

  .placeholder {
    text-align: left;
  }
}

#nav + .container.content {
  display: flex;
  flex: auto;
  flex-direction: column;

  width: 100%;
  //noinspection CssReplaceWithShorthandSafely
  padding: 7.5% calc(2 * var(--content-padding));
  //noinspection CssReplaceWithShorthandSafely
  padding-bottom: calc(10 * var(--content-padding));
}

.card {
  margin: 0 auto calc(2 * var(--content-padding));

  overflow-y: auto;

  &:not(.action) {
    padding: calc(2 * var(--content-padding));
    width: 80ch;
  }

  flex-shrink: 0;
  max-width: calc(100% - 4 * var(--content-padding));
}

.error {
  text-align: left;
  color: var(--red);
  font-weight: 300;
  font-size: 0.9rem;
}

.input {
  margin-bottom: var(--content-padding);
}
</style>
