<template>
  <div class="container">
    <div class="logo">
      <div>
        <img src="@/assets/logo.png" alt="WaterScope Logo" />
        <h1>WaterScope</h1>
      </div>
    </div>
    <div>
      <card class="card">
        <h2>{{ title }}</h2>
        <form @submit.prevent>
          <text-field
            v-if="mode !== 'new-password'"
            label="E-Mail"
            placeholder="your.mail@provider.com"
            type="email"
            v-model="email"
          />
          <text-field
            v-if="mode !== 'reset'"
            label="Password"
            placeholder="password1234"
            type="password"
            v-model="password"
          />
          <div v-if="errorMessage.length > 0 || mode === 'sign-in'">
            <p class="error">
              {{ errorMessage }}
            </p>
            <router-link
              :to="{ name: 'Auth', query: { mode: 'reset' } }"
              v-if="mode === 'sign-in'"
            >
              Forgot password?
            </router-link>
          </div>
        </form>
      </card>
      <div class="actions">
        <router-link :to="alternative.route">
          {{ alternative.title }}
        </router-link>
        <action-element class="action" @click="submit">
          {{ submitText }}
        </action-element>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "@/components/base/TextField";
import Card from "@/components/base/Card";
import ActionElement from "@/components/base/ActionElement";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "Auth",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from ?? { name: "Dashboard" };
      vm.$nextTick(() => vm.$nextTick(() => vm.$forceUpdate()));
    });
  },
  beforeRouteUpdate() {
    this.errorMessage = "";
  },
  components: { ActionElement, Card, TextField },
  computed: {
    mode() {
      return this.$route.query.mode ?? "sign-in";
    },
    title() {
      switch (this.mode) {
        case "sign-up":
          return "Sign up";
        case "reset":
          return "Reset password";
        case "new-password":
          return "Set new password";
        default:
          return "Sign in";
      }
    },
    alternative() {
      let route = { name: "Auth", query: { mode: undefined } };
      let title;

      switch (this.mode) {
        case "sign-in":
          title = "Sign up instead";
          route.query.mode = "sign-up";
          break;
        default:
          title = "Sign in instead";
          route.query.mode = "sign-in";
          break;
      }

      return { route, title };
    },
    submitText() {
      switch (this.mode) {
        case "sign-up":
          return "Sign up";
        case "reset":
          return "Send password reset email";
        case "new-password":
          return "Set new password";
        default:
          return "Sign in";
      }
    },
  },
  methods: {
    submit() {
      switch (this.mode) {
        case "sign-up":
          this.signUp();
          break;
        case "reset":
          this.sendResetMail();
          break;
        case "new-password":
          this.resetPassword();
          break;
        default:
          this.signIn();
          break;
      }
    },
    async signIn() {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
      } catch (e) {
        this.errorMessage = e.message;
        return;
      }

      await this.$router.push(this.from);
    },
    async signUp() {
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password);
      } catch (e) {
        this.errorMessage = e.message;
        return;
      }

      await this.$router.push(this.from);
    },
    async sendResetMail() {
      try {
        await firebase.auth().sendPasswordResetEmail(this.email);
      } catch (e) {
        this.errorMessage = e.message;
        return;
      }

      await this.$router.push({ name: "Auth" });
    },
    async resetPassword() {
      try {
        await firebase
          .auth()
          .confirmPasswordReset(this.$route.query.oobCode ?? "", this.password);
      } catch (e) {
        this.errorMessage = e.message;
        return;
      }

      await this.$router.push({ name: "Dashboard" });
    },
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  font-size: 0.9rem;
  width: fit-content;
  color: var(--dark-blue);

  height: fit-content;
}

.container {
  position: relative;
  display: block;

  width: 100%;

  * {
    transition: none;
  }
}

.logo {
  width: calc(100% - 2 * var(--content-padding));
  background: var(--dark-blue);
  padding: var(--content-padding);

  color: white;

  div {
    display: flex;
    align-items: center;

    max-width: 67ch;
    margin: auto;

    img {
      height: 1.9rem;
    }

    > :first-child {
      margin-right: calc(1.5 * var(--content-padding));
      margin-left: calc(0.5 * var(--content-padding));
    }
  }
}

.container > :last-child {
  height: fit-content;
  min-height: calc(100% - 8 * var(--content-padding) - 20vh);

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: calc(2 * var(--content-padding));
  padding-bottom: 20vh;

  > * {
    margin: var(--content-padding) auto 0;
  }
}

.card:not(.action) {
  height: fit-content;

  margin-bottom: var(--content-padding);

  width: 60ch;
  max-width: calc(100% - 6 * var(--content-padding));
  padding: calc(2 * var(--content-padding));
}

form {
  > *:not(:first-child) {
    padding-top: var(--content-padding);
  }

  a {
    display: block;
    margin-left: auto;
    padding-right: 0;
  }

  > :last-child:not(div) {
    margin-bottom: var(--content-padding);
  }

  .error {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--red);
    text-align: left;

    flex: 1;

    & + * {
      margin-left: var(--content-padding);
    }
  }

  div {
    margin-top: 0.5rem;
    display: flex;
  }
}

.action {
  margin: 0;
}

.container .actions {
  width: 66ch;
  max-width: calc(100% - 2 * var(--content-padding));
  display: flex;

  justify-content: center;

  > :first-child {
    margin: auto var(--content-padding) auto auto;
  }
}
</style>
