<template>
  <router-link v-if="!isExternal(page)" :to="page.page ?? { path: '#' }">
    <component :is="page.icon" />
    <div v-if="showText">{{ page.text }}</div>
  </router-link>
  <a v-else :href="page.href" class="extern" target="_blank">
    <component :is="page.icon" />
    <div v-if="showText">{{ page.text }}</div>
  </a>
</template>

<script>
function isExternal(page) {
  return page.href !== undefined;
}

export default {
  name: "action-link",
  props: {
    showText: {
      required: false,
      type: Boolean,
      default: true,
    },
    page: {
      required: true,
      type: Object,
      validator(value) {
        return (
          (value.page !== undefined || value.href !== undefined) &&
          value.icon !== undefined
        );
      },
    },
  },
  methods: {
    isExternal,
  },
};
</script>

<style lang="scss" scoped>
a {
  display: flex;
  align-content: center;

  text-align: left;

  width: fit-content;
  margin-left: auto;

  div {
    margin: auto 0 auto calc(0.5 * var(--content-padding));
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;

    margin: auto;
  }
}
</style>
