import {
  addSampleListeners,
  getColonyLevel,
  removeSampleListeners,
} from "@/store/samples";
import { linking } from "@/store/config";
import { db } from "@/store/index";

export function setDevice(state, { id, value }) {
  const samples = state.devices[id]?.samples ?? {};

  if (value !== undefined) {
    state.devices[id] = { samples, ...value };
  } else {
    Object.values(samples).forEach((sample) => {
      --state.stats[getColonyLevel(sample)];
    });

    delete state.devices[id];
  }
}

export function setDeviceVisibility(state, { id, hidden }) {
  state.devices[id].hidden = hidden;
}

export function clearDevices(state) {
  state.devices = {};
}

const deviceListeners = {};
const identifierListener = {};

async function bind(commit, devices) {
  devices = new Set(devices);

  Object.keys(identifierListener).forEach((identifier) => {
    if (!devices.has(identifier)) {
      removeSampleListeners(identifier);

      commit("setDevice", { id: identifier, value: undefined });

      deviceListeners[identifier]();
      delete deviceListeners[identifier];

      identifierListener[identifier]();
      delete identifierListener[identifier];
    } else {
      devices.delete(identifier);
    }
  });

  await linking;

  devices = Array.from(devices);

  const fetchers = devices.map((identifier) => {
    return new Promise((resolve) => {
      identifierListener[identifier] = db
        .collection("deviceIdentifiers")
        .doc(identifier)
        .onSnapshot((doc) => {
          deviceUpdateHandler({ doc, commit, resolve });
        });
    });
  });

  return Promise.all(fetchers);
}

async function bindAll(commit, hidden) {
  return new Promise((resolve) => {
    db.collection("deviceIdentifiers").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          const id = change.doc.id;
          commit("setDevice", { id, value: undefined });

          removeSampleListeners(id);
          deviceListeners[id]();
          delete deviceListeners[id];
        } else {
          deviceUpdateHandler({
            doc: change.doc,
            commit,
            resolve,
            hidden: hidden.has(change.doc.id),
          });
        }
      });
    });
  });
}

export async function bindDevices({ commit }, { devices = [], admin = false } = {}) {
  if (admin) {
    return bindAll(commit, new Set(devices ?? []));
  } else {
    return bind(commit, devices ?? []);
  }
}

function deviceUpdateHandler({ doc, commit, resolve, hidden }) {
  hidden = hidden ?? false;

  const identifier = doc.data();
  const id = identifier.identifier;

  if (!(id in deviceListeners)) {
    deviceListeners[id] = identifier.info.onSnapshot((doc) => {
      commit("setDevice", {
        id,
        value: {
          ...identifier,
          ref: identifier.info,
          hidden,
          info: doc.data(),
        },
      });

      if (!hidden) {
        addSampleListeners({ commit, identifier });
      } else {
        removeSampleListeners(identifier);
        commit("removeAllSamples", { deviceID: id });
      }

      resolve();
    });
  } else {
    commit("setDeviceVisibility", {
      id,
      hidden,
    });

    if (hidden) {
      removeSampleListeners(id);
      commit("removeAllSamples", { deviceID: id });
    } else {
      addSampleListeners({ commit, identifier });
    }
  }
}
