<template>
  <card class="action">
    <slot />
  </card>
</template>

<script>
import Card from "@/components/base/Card";

export default {
  name: "ActionElement",
  components: {
    Card,
  },
};
</script>

<style lang="scss">
.action {
  cursor: pointer;

  * {
    cursor: pointer;
  }

  padding: calc(var(--content-padding) / 2) var(--content-padding);

  border-radius: 2rem;

  width: fit-content;
  height: fit-content;

  display: flex;
  justify-content: center;
}
</style>
