<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card",
};
</script>

<style scoped>
.card {
  background: white;

  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
  border-radius: 1rem;

  margin: var(--content-padding);
  margin-bottom: calc(2 * var(--content-padding));
}
</style>

<style>
.card h2 {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: start;
}
</style>
