<template>
  <navigation-bar />
  <entry-creation-container
    v-if="initialized"
    v-model="input"
    :action="action"
    :entry="entry"
    :error="errorMessage"
    :device="{ info: device }"
  >
  </entry-creation-container>
</template>

<script>
import Add from "@/assets/linkAdd.svg";
import NavigationBar from "@/components/NavigationBar";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { debounce } from "vue-debounce";
import { init } from "@/store";
import EntryCreationContainer from "@/components/base/DeviceForm";
import { markRaw } from "vue";

export default {
  name: "LinkDevice",
  components: {
    EntryCreationContainer,
    NavigationBar,
  },
  async created() {
    await init;
    this.initialized = true;

    if (this.$store.state.isAdmin) {
      await this.$router.replace({ name: "Add" });
    }
  },
  computed: {
    errorMessage() {
      const idLength = 20;
      let input = this.input.trim();
      let length = input.length;

      if (input.split(" ").length !== 1) {
        return "Identifier cannot contain spaces";
      } else if (input in (this.$store.state.devices ?? {})) {
        return "Device is already linked";
      } else if (length) {
        if (length < idLength) {
          return `Identifier is missing ${idLength - length} characters`;
        } else if (length > idLength) {
          return `Identifier is ${length - idLength} characters too long`;
        } else if (this.device === null) {
          return "No device found";
        }
      }

      return "";
    },
    validInput() {
      let error = this.errorMessage;

      if (error === "Device is already linked") {
        error = "";
      }

      return this.input.trim().length > 0 && error === "";
    },
    loadDevice() {
      return debounce(async (identifier) => {
        let device = (
          await firebase
            .firestore()
            .collection("deviceIdentifiers")
            .doc(identifier.trim())
            .get()
        ).data();

        if (device === undefined) {
          this.device = null;
          return;
        }

        let info = (await device.info?.get())?.data();

        if (this.validInput) {
          this.device = [
            { label: "Name", value: info?.name ?? "" },
            {
              label: "Bluetooth MAC-Address",
              value: device.macAddress ?? "",
            },
            { label: "Operator", value: info?.operator ?? "" },
            { label: "Location", value: info?.location ?? "" },
          ];
        }
      }, 300);
    },
    action() {
      return {
        icon: markRaw(Add),
        text: "Link this device",
        action: markRaw(this.link),
        enabled: Boolean(this.device && this.errorMessage === ""),
      };
    },
  },
  watch: {
    input: {
      immediate: true,
      handler(identifier) {
        if (this.validInput) {
          // noinspection JSCheckFunctionSignatures
          this.loadDevice(identifier);
        } else {
          this.device = undefined;
        }
      },
    },
  },
  methods: {
    async link() {
      let id = this.input.trim();
      await this.$store.dispatch("linkDevice", id);
      await this.$router.push({ name: "Device", params: { id } });
    },
  },
  data() {
    return {
      input: this.$route.query.identifier ?? "",
      device: undefined,
      initialized: false,
      entry: markRaw({
        title: "Link a new device",
        label: "Device identifier",
        placeholder: "abcDEFG0123",
      }),
    };
  },
};
</script>
