<template>
  <card class="card">
    <div>
      <div :series="series" id="donut" />
      <div>
        <h6>Number of tests</h6>
        <h1>{{ testCount }}</h1>
      </div>
    </div>
    <div>
      <h2>Test results overview</h2>
      <table>
        <thead>
          <tr>
            <th>CFU count</th>
            <th>Test with result</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(key, index) in Object.keys(results)" :key="key">
            <td :style="{ color: colors[index] }">{{ key }}</td>
            <td :style="{ color: colors[index] }">{{ results[key] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>

<script>
import Card from "@/components/base/Card";
import Highcharts from "highcharts";
// noinspection ES6CheckImport
import { markRaw } from "vue";

const colors = markRaw([
  "hsl(110,45%,55%)",
  "hsl(48,100%,47.5%)",
  "hsl(30,100%,50%)",
  "hsl(0,100%,42%)",
  "hsl(0,100%,30%)",
  "hsl(0,0%,40%)",
]);

const chartOptions = markRaw({
  chart: {
    type: "pie",
    width: 300,
    height: 300,
  },
  plotOptions: {
    borderColor: null,
    pie: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  tooltip: {
    pointFormat: "<b>{point.percentage:.1f}%</b> of results",
  },
  title: "",
  credits: {
    enabled: false,
  },
});

export default {
  name: "ResultOverview",
  components: { Card },
  mounted() {
    this.chart = Highcharts.chart("donut", {
      ...chartOptions,
      series: this.series,
    });
  },
  computed: {
    testCount() {
      const stats = this.$store.state.stats;
      return Object.values(stats).reduce((a, b) => a + b, 0);
    },
    results() {
      const stats = this.$store.state.stats;

      return {
        None: stats.none,
        "1 to 10": stats.low,
        "11 to 50": stats.medium,
        "51 to 100": stats.high,
        "above 100": stats.veryHigh,
        Outstanding: stats.outstanding,
      };
    },
    series() {
      return [
        {
          innerSize: "80%",
          data: Object.keys(this.results).map((key, index) => ({
            name: key,
            y: this.results[key],
            color: this.colors[index],
          })),
        },
      ];
    },
  },
  watch: {
    series(value) {
      let series = this.chart?.series;

      if (series !== undefined) {
        series = series[0];
        this.chart?.series[0].setData(value[0].data);
      }
    },
  },
  data() {
    return {
      colors,
      chart: undefined,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;

  overflow-y: auto;

  > * {
    margin: var(--content-padding);
    padding: calc(var(--content-padding) * 2) var(--content-padding);
  }

  > :last-child {
    height: fit-content;

    margin-bottom: auto;
    margin-top: auto;
  }

  > :first-child {
    position: relative;

    > :last-child {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}

table {
  margin-top: var(--content-padding);
  border-collapse: collapse;
}

td,
th {
  padding: calc(var(--content-padding) / 2) var(--content-padding);

  &:first-child {
    text-align: right;
    border-right: 1px solid black;
    padding-left: 0;
  }

  &:last-child {
    text-align: left;
    padding-right: 0;
  }
}
</style>
