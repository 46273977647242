import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import History from "@/views/History.vue";
import History2 from "@/views/History2.vue";
import Devices from "@/views/Devices.vue";
import LinkDevice from "@/views/LinkDevice";
import AddDevice from "@/views/AddDevice";
import Device from "@/views/Device";
import Auth from "@/views/Auth";
import NotFound from "@/views/404";

import { store, userAvailable } from "@/store";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/sample-history",
    name: "History",
    component: History,
  },
  {
    path: "/sample-history2",
    name: "History2",
    component: History2,
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices,
  },
  {
    path: "/device/:id",
    name: "Device",
    component: Device,
  },
  {
    path: "/device/link",
    name: "Link",
    component: LinkDevice,
  },
  {
    path: "/device/add",
    name: "Add",
    component: AddDevice,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  await userAvailable();

  if (store.state.user === null && to.name !== "Auth") {
    next({ name: "Auth" });
  } else if (store.state.user !== null && to.name === "Auth") {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
