<template>
  <content-editable
    v-if="type === 'text'"
    tag="p"
    :placeholder="placeholder"
    :label="label"
    :class="inputClasses"
    v-model="input"
    @update:model-value="updateModel"
    :noNL="!multiline"
  />
  <!--suppress HtmlUnknownAttribute -->
  <label v-else :class="inputClasses" :label="label">
    <input
      :type="type"
      v-model="input"
      :placeholder="placeholder"
      @update:model-value="updateModel"
    />
  </label>
</template>
<script>
import ContentEditable from "vue-contenteditable";

export default {
  name: "text-field",
  components: { ContentEditable },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    multiline: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.input = value;
      },
    },
  },
  computed: {
    inputClasses() {
      return {
        input: true,
        empty: this.input.length === 0,
        labeled: this.label.length !== 0,
      };
    },
  },
  methods: {
    updateModel() {
      this.$emit("update:modelValue", this.input);
    },
  },
  data() {
    return {
      input: "",
    };
  },
};
</script>
<style lang="scss" scoped>
svg {
  height: 1.4rem;
  width: 1.4rem;

  margin-right: calc(var(--content-padding) / 2);
}

.input {
  position: relative;
  display: block;
  margin: calc(1.75 * var(--content-padding)) 0 0;
  outline: none;

  text-align: left;
  font-size: 1rem;

  border-bottom: 1px solid var(--dark-blue);
  padding-bottom: calc(var(--content-padding) / 4);

  transition: 100ms ease-in-out;

  word-break: break-word;

  &:before,
  &:after {
    transition: 100ms ease-in-out;
  }

  &:before {
    pointer-events: none;

    position: absolute;
    content: attr(label);
    color: var(--dark-blue);
  }

  &:not(.empty):before {
    font-size: 0.9rem;
    transform: translate(0, -105%);

    color: var(--light-blue);
  }

  &.empty:after {
    position: absolute;
    content: attr(placeholder);

    font-weight: 300;
    font-style: italic;
    color: hsl(0, 0%, 37%);
  }

  &.labeled.empty:after {
    opacity: 0;
  }

  &:focus,
  &:focus-within {
    border-color: var(--light-blue);

    &:before {
      font-size: 0.9rem;
      transform: translate(0, -105%);

      color: var(--light-blue);
    }

    &.labeled:after {
      opacity: 1;
    }
  }
}

input {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  font-size: 1rem;
  background: none;
  outline: none;

  transition: 100ms ease-in-out;

  &::placeholder {
    opacity: 0;
  }

  &:focus::placeholder {
    opacity: 1;
  }
}
</style>
