import { createStore } from "vuex";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

import {
  linkDevice,
  setConfig,
  unlinkDevice,
  bindConfig,
  unbindConfig,
  hideDevice,
  showDevice,
} from "@/store/config";
import {
  clearStats,
  removeAllSamples,
  setSample,
  setSampleImages,
  setSampleDownload,
  getImageURLs,
} from "@/store/samples";
import {
  bindDevices,
  clearDevices,
  setDevice,
  setDeviceVisibility,
} from "@/store/devices";
import { debug } from "@/main";

export let db;

export let storage;

export const store = createStore({
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setConfig,
    setDevice,
    setDeviceVisibility,
    setSample,
    removeAllSamples,
    setSampleImages,
    setSampleDownload,
    clearStats,
    clearDevices,
      // New mutations for updating and reverting sample location
    updateSampleLocation(state, { deviceIdentifier, sampleID, newLocation, newCoordinates, macAddress }) {
      const coordinatesString = newCoordinates;
const [latitude, longitude] = coordinatesString.split(",").map(coord => parseFloat(coord.trim()));

console.log("Latitude:", latitude);
console.log("Longitude:", longitude);
const coordinatesObject = {
  latitude,
  longitude
};
      const numSampleID = Number(sampleID);
      console.log("MUTATION");
      console.log("Passed sampleID:", numSampleID);
      console.log('Specific device:', state.devices[deviceIdentifier]);
      console.log('Specific sampleID:', state.devices[deviceIdentifier].samples[numSampleID]);
      if (state.devices[deviceIdentifier]) {
        console.log("Available sampleIDs:", Object.keys(state.devices[deviceIdentifier].samples));
      }
      if (state.devices[deviceIdentifier] && state.devices[deviceIdentifier].samples[numSampleID]) {
        const oldLocation = state.devices[deviceIdentifier].samples[numSampleID].location;
        console.log("Old Location:", oldLocation);
        state.devices[deviceIdentifier].samples[numSampleID].location = newLocation;
        const oldCoordinates = state.devices[deviceIdentifier].samples[numSampleID].coordinates;

        console.log("Old Location:", oldCoordinates);
        if (newCoordinates) {
          state.devices[deviceIdentifier].samples[numSampleID].coordinates = newCoordinates;
        }
// Define Firestore document references for both possible collections
const docRefSamples = db.collection('devices').doc(macAddress).collection('samples').doc(String(numSampleID));
const docRefSamplesIoT = db.collection('devices').doc(macAddress).collection('samples_iot').doc(String(numSampleID));
console.log(docRefSamples);
console.log(docRefSamplesIoT);
// Check if the document exists in the 'samples' collection
docRefSamples.get().then(docSamples => {
  if (docSamples.exists) {
    // Update in 'samples' collection
    docRefSamples.update({
      location: newLocation,
      coordinates: coordinatesObject
    })
    .then(() => {
      console.log("Successfully updated document in 'samples'");
    })
    .catch(error => {
      console.error("Error updating document in 'samples': ", error);
    });
  } else {
    console.log("No such document in 'samples' collection.");
  }
})
.catch(error => {
  console.error("Error fetching document from 'samples': ", error);
});

// Check if the document exists in the 'samples_iot' collection
docRefSamplesIoT.get().then(docSamplesIoT => {
  if (docSamplesIoT.exists) {
    // Update in 'samples_iot' collection
    docRefSamplesIoT.update({
      location: newLocation,
      coordinates: coordinatesObject
    })
    .then(() => {
      console.log("Successfully updated document in 'samples_iot'");
    })
    .catch(error => {
      console.error("Error updating document in 'samples_iot': ", error);
    });
  } else {
    console.log("No such document in 'samples_iot' collection.");
  }
})
.catch(error => {
  console.error("Error fetching document from 'samples_iot': ", error);
});
  
      }
      else {
        console.log("IF check did not work");

      }
    },
  },
  actions: {
    bindConfig,
    bindDevices,
    unbindConfig,
    linkDevice,
    unlinkDevice,
    hideDevice,
    showDevice,
    getImageURLs,
  },
  state() {
    return {
      stats: {
        none: 0,
        low: 0,
        medium: 0,
        high: 0,
        veryHigh: 0,
        outstanding: 0,
      },
      devices: {},
      config: null,
      downloads: {},
      user: undefined,
      isAdmin: false,
    };
  },
});

export async function userAvailable() {
  if (store.state.user === undefined) {
    await new Promise((resolve) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(() => {
        unsubscribe();
        resolve();
      });
    });
  }
}

export let init;

export function onAuthChange(user) {
  if (debug) firebase.functions().useEmulator("localhost", 5001);

  store.commit("setUser", user);

  if (user) {
    // init promise has to get initialized in sync
    init = (async () => {
      if (!db) {
        db = firebase.firestore();
        await db.enablePersistence({
          synchronizeTabs: true,
        });
      }

      if (!storage) {
        storage = firebase.storage();
      }

      const current = (await user.getIdTokenResult()).claims?.admin ?? false;
      let admin;

      try {
        admin = await firebase.functions().httpsCallable("adminCheck")();
        admin = admin.data;

        if (current !== admin.data) {
          await user.getIdToken(true);
          store.commit("setUser", firebase.auth().currentUser);
        }
      } catch (e) {
        console.error(e);
      }

      store.commit("setAdmin", admin ?? current);
      await store.dispatch("bindConfig", admin ?? current);
    })();
  } else {
    store.commit("setAdmin", false);
    return store
      .dispatch("bindDevices")
      .then(() => store.dispatch("unbindConfig"));
  }
}
