<template>
  <navigation-bar />
  <div class="content">
    <div>
      <h1>404</h1>
      <h6>Not found</h6>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
export default {
  name: "404",
  components: { NavigationBar },
};
</script>

<style scoped lang="scss">
.content {
  display: grid;
  place-items: center;
  width: 100%;

  div {
    padding: var(--content-padding);
    padding-bottom: calc(8 * var(--content-padding));
  }
}
</style>
