<template>
  <div class="image-switcher">
    <card class="card" v-if="shown !== 0">
      <button @click="--shown"><chevron /></button>
    </card>
    <img
      :src="urls[shown]"
      :alt="altText"
      v-if="typeof urls[shown] === 'string'"
    />
    <div v-else>
      <p class="placeholder">No {{ names[shown] }} image available</p>
    </div>
    <card class="card" v-if="shown < urls.length - 1">
      <button @click="++shown"><chevron /></button>
    </card>
  </div>
</template>

<script>
import Card from "@/components/base/Card";
import Chevron from "@/assets/chevron.svg";

export default {
  name: "ImageSwitcher",
  components: { Card, Chevron },
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  computed: {
    names() {
      return Object.keys(this.images);
    },
    urls() {
      return Object.values(this.images);
    },
    altText() {
      const name = this.names[this.shown];
      return `${name.charAt(0).toUpperCase() + name.slice(1)} image`;
    },
  },
  data() {
    return {
      shown: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-switcher {
  position: relative;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  button {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;
  }

  .card {
    margin: 0;
    width: 2rem;
    height: 2rem;

    &:first-child {
      position: absolute;
      top: 50%;
      transform: translate(-65%, -50%);

      button svg {
        transform: rotate(180deg);
      }
    }

    &:last-child {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(65%, -50%);
    }
  }

  img {
    width: 100%;
  }

  .placeholder {
    margin: auto;
    width: 80%;
  }
}
</style>
