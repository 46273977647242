<template>
  <table>
    <tr v-for="(entry, i) in data" :key="entry.label">
      <td :style="labelWidth ? { width: labelWidth } : {}">
        <h6>{{ entry.label }}</h6>
      </td>
      <td>
        <text-field
          v-if="entry.editable"
          :model-value="entry.value"
          :placeholder="entry.placeholder"
          @update:modelValue="update(i, $event)"
        />
        <span v-else :class="{ placeholder: (entry.value?.length ?? 0) === 0 }">
          {{ (entry.value?.length ?? 0) > 0 ? entry.value : "Unknown" }}
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import TextField from "@/components/base/TextField";
export default {
  name: "SplitData",
  components: { TextField },
  emits: ["update:data"],
  props: {
    data: {
      type: Array,
      required: true,
      validator(value) {
        return (
          value.length !== 0 &&
          !value.some(
            (entry) => entry.label === undefined || entry.value === undefined,
          )
        );
      },
    },
    labelWidth: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    update(index, value) {
  if (Array.isArray(this.data)) {
    let data = this.data.slice();
    data[index].value = value;
    this.$emit("update:data", data);
    console.error("Data prop is an array:", this.data);
    console.log('Data prop value:', this.data);


  } else {
    console.error("Data prop is not an array:", this.data);
    console.log('Data prop value:', this.data);
    console.log('Data prop type:', typeof this.data);
  }
},
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;

  display: block;
  width: 100%;
  overflow-x: auto;

  td {
    padding: calc(var(--content-padding) / 2) var(--content-padding);
  }

  td:first-child {
    border-right: 1px solid black;
    padding-left: 0;

    * {
      text-align: right;
    }
  }

  td:last-child {
    text-align: left;

    min-width: 20ch;

    > * {
      margin-top: 0;
    }
  }
}
</style>
