<template>
  <card class="card">
    <h2>{{ title }}</h2>
    <v-table :data="results" v-if="results.length > 0">
      <template #head>
        <tr>
          <th>Location</th>
          <th>E. coliform</th>
          <th>Other coliform</th>
          <th>Date</th>
          <th>Coordinates</th>  <!-- Add this line -->
        </tr>
      </template>
      <template #body="{ rows }">
        <tr v-for="row in rows" :key="row.id">
          <td>{{ row.location }}</td>
          <td>{{ row.eColi }}</td>
          <td>{{ row.otherColi }}</td>
          <td>{{ row.date.toLocaleDateString() }}</td>
          <td>{{ row.coordinates }}</td>  <!-- Add this line -->
        </tr>
      </template>
    </v-table>
    <p v-else class="placeholder">No sample found</p>
    <action-link :page="action" />
  </card>
</template>

<script>
import Card from "@/components/base/Card";
import History from "@/assets/history.svg";
import ActionLink from "@/components/base/ActionLink";
import { markRaw } from "vue";
import { debug } from "@/main";

export default {
  name: "RecentResults",
  components: { ActionLink, Card },
  props: {
    device: {
      type: String,
      required: false,
    },
  },
  computed: {
    specificDevice() {
      return this.device !== undefined;
    },
    title() {
      let title = "Most recent samples";
      if (this.specificDevice) {
        title = `${title} from this device`;
      }

      return title;
    },
    action() {
      let text = "View all samples";
      if (this.specificDevice) {
        text = `${text} from this device`;
      }

      return {
        icon: markRaw(History),
        page: { name: "History", query: { device: this.device } },
        text: text,
      };
    },
    results() {
      if (debug) console.time("recent results");

      let samples = [];

      if (this.specificDevice) {
        samples = Object.values(
          this.$store.state.devices[this.device]?.samples ?? {},
        );
      } else {
        let earliest = new Date();

        Object.values(this.$store.state.devices).forEach((device) => {
          Object.values(device?.samples ?? {}).forEach((sample) => {
            const submission = sample.submissionTime;

            if (samples.length < 10) {
              samples.push(sample);
              earliest = earliest < submission ? earliest : submission;
            } else if (submission > earliest) {
              samples.push(sample);
              samples = samples.sort(this.compareDates);

              earliest = samples[samples.length - 2].submissionTime;
              samples.splice(10);
            }
          });
        });
      }

      samples = samples
    .sort(this.compareDates)
    .slice(0, 14)
    .map((sample) => {
      let coordinates = 'No coordinates';
      if (sample.coordinates) {
        coordinates = `${sample.coordinates.latitude}, ${sample.coordinates.longitude}`;
      }
      return {
        location: sample.location,
        eColi: sample.eColiform,
        otherColi: sample.otherColiform,
        date: sample.submissionTime,
        coordinates: coordinates,
      };
    });


      if (debug) console.timeEnd("recent results");

      return samples;
    },
  },
  methods: {
    compareDates(a, b) {
      return b.submissionTime.getTime() - a.submissionTime.getTime();
    },
  },
};
</script>

<style scoped lang="scss">
@use "/src/components/base/table";

th {
  width: 24%;
}

th:nth-child(1) {
  width: 40%;
}

th:nth-child(4) {
  width: 12%;
}

.action {
  margin-left: auto;
}

.placeholder {
  padding: 1rem 0 1.5rem;
}
</style>
