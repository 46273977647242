<template>
  <div id="nav-placeholder" />
  <div id="nav" :class="{ expanded }">
    <div>
      <img alt="WaterScope Logo" src="@/assets/logo.png" />
      <h1 v-if="expanded">WaterScope</h1>
    </div>
    <action-link
      v-for="page in pages"
      :key="page.text"
      :show-text="expanded"
      :page="page"
    />
    <button @click="signOut">
      <logout />
      <span v-if="expanded">Sign out</span>
    </button>
    <button @click="expanded = !expanded">
      <chevron :class="{ expanded }" />
    </button>
  </div>
</template>

<script>
import Book from "@/assets/book.svg";
import Dashboard from "@/assets/dashboard.svg";
import History from "@/assets/history.svg";
import Link from "@/assets/link.svg";
import ShoppingCart from "@/assets/shoppingCart.svg";
import Chevron from "@/assets/chevron.svg";
import Logout from "@/assets/logout.svg";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { markRaw } from "vue";
import ActionLink from "@/components/base/ActionLink";

const pages = markRaw([
  {
    icon: Dashboard,
    page: { name: "Dashboard" },
    text: "Dashboard",
  },
  {
    icon: History,
    page: { name: "History" },
    text: "Sample history",
  },
  {
    icon: Link,
    page: { name: "Devices" },
    text: "Linked devices",
  },
  {
    icon: Book,
    href: "https://example.com",
    text: "WaterScope instructions",
  },
  {
    icon: ShoppingCart,
    href: "https://example.com",
    text: "Order consumables",
  },
]);

const cookieKey = "navigationExpanded";

export default {
  name: "NavigationBar",
  components: {
    ActionLink,
    Chevron,
    Logout,
  },
  computed: {
    expanded: {
      get() {
        // used to force it to recompute when set
        // noinspection BadExpressionStatementJS
        this.ticker;

        return this.$cookies.get(cookieKey) === "true";
      },
      set(value) {
        this.$cookies.set(cookieKey, value.toString(), "1y");
        this.ticker++;
      },
    },
  },
  methods: {
    async signOut() {
      await firebase.auth().signOut();
      this.$cookies.remove(cookieKey);
      await this.$router.push({ name: "Auth" });
    },
  },
  data() {
    return {
      pages,
      ticker: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
#nav {
  background: white;

  border-right: 2px solid var(--dark-blue);

  display: flex;
  flex-direction: column;
  align-content: center;

  overflow-y: auto;

  flex-shrink: 0;

  div {
    margin: 2.75rem 0;

    background: var(--dark-blue);

    display: flex;
    align-content: center;

    img {
      display: block;
      margin: 0.5rem;
      height: 1.5rem;
    }

    h1 {
      margin: auto 0.5rem;
      color: white;
      font-size: 1.5rem;
    }
  }

  button:not(:last-child) {
    width: calc(100% - 2 * var(--content-padding));
    max-width: fit-content;
    margin-top: auto;

    display: flex;

    padding: 0;

    span {
      display: block;
      margin: auto var(--content-padding) auto 1.45rem;
    }
  }

  button:last-child {
    display: block;

    &:last-child {
      padding: 0.55rem 0.9rem;
      border-top: 2px solid var(--dark-blue);
    }

    svg {
      display: block;
      margin-left: auto;
    }

    .expanded {
      transform: rotate(180deg);
    }
  }
}

#nav-placeholder {
  display: none;
}

@media (max-width: 130ch) {
  #nav {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;

    z-index: 100000;

    &.expanded {
      box-shadow: 0.25rem 0 0.4rem rgba(0, 0, 0, 0.4);
    }
  }

  #nav-placeholder {
    display: block;

    flex-basis: calc(3.75rem + 2px);
    flex-shrink: 0;
    flex-grow: 0;
  }
}
</style>

<style lang="scss">
#nav {
  a,
  button:not(:last-child) {
    margin: 0 0.9rem 1.1rem;

    font-size: 1.1rem;
    font-weight: 300;
    color: var(--dark-blue);

    div,
    span {
      margin-left: 1.45rem;
      margin-right: var(--content-padding);
    }
  }

  svg {
    width: 1.9rem;
    height: 1.9rem;
  }
}
</style>
