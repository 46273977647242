<template>
  <navigation-bar />
  <dynamic-grid class="content">
    <template #first>
      <result-overview class="card" />
      <location-overview class="card" />
    </template>
    <template #second>
      <recent-results class="card" />
      <equipment-overview class="card" />
    </template>
  </dynamic-grid>
</template>

<script>
import RecentResults from "@/components/dashboard/RecentResults";
import EquipmentOverview from "@/components/dashboard/EquipmentOverview";
import ResultOverview from "@/components/dashboard/ResultOverview";
import LocationOverview from "@/components/dashboard/LocationOverview";
import DynamicGrid from "@/components/base/DynamicGrid";
import NavigationBar from "@/components/NavigationBar";

export default {
  name: "Dashboard",
  components: {
    NavigationBar,
    DynamicGrid,
    LocationOverview,
    ResultOverview,
    EquipmentOverview,
    RecentResults,
  },
};
</script>
