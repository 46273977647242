import { createApp } from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";

import router from "@/router";
import { onAuthChange, store } from "@/store";

import VueCookies from "vue3-cookies";
// noinspection NpmUsedModulesInstalled
import SmartTable from "vuejs-smart-table";

import { ObserveVisibility } from "vue-observe-visibility";


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import "leaflet/dist/leaflet.css";

firebase.initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
});

export const debug = process.env.NODE_ENV !== "production";

firebase.auth().onAuthStateChanged(onAuthChange);

let app = createApp(App);

app.use(router);
app.use(store);
// noinspection JSCheckFunctionSignatures
app.use(VueCookies);
app.use(SmartTable);

app.directive("observe-visibility", {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});

app.mount("#app");
