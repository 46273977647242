import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { db, store } from "@/store/index";

export let linking = new Promise((resolve) => resolve());

export function setConfig(state, config) {
  state.config = config;
}

export async function linkDevice({ state }, identifier) {
  linking = new Promise((resolve) => {
    const config = firebase.firestore().collection("users").doc(state.user.uid);

    if (state.config) {
      config
        .update({
          devices: firebase.firestore.FieldValue.arrayUnion(identifier),
        })
        .then(() => resolve());
    } else {
      config.set({ devices: [identifier] }).then(() => resolve());
    }
  });

  return linking;
}

export function unlinkDevice({ state }, identifier) {
  return db
    .collection("users")
    .doc(state.user.uid)
    .update({
      devices: firebase.firestore.FieldValue.arrayRemove(identifier),
    });
}

export function hideDevice({ state }, identifier) {
  return db
    .collection("users")
    .doc(state.user.uid)
    .update({
      hidden: firebase.firestore.FieldValue.arrayUnion(identifier),
    });
}

export function showDevice({ state }, identifier) {
  return db
    .collection("users")
    .doc(state.user.uid)
    .update({
      hidden: firebase.firestore.FieldValue.arrayRemove(identifier),
    });
}

let listener;

export function bindConfig({ commit, dispatch }, admin = false) {
  return new Promise((resolve) => {
    const doc = db.collection("users").doc(store.state.user.uid);

    listener = doc.onSnapshot((doc) => {
      if (!doc.exists) {
        doc.ref.set({ devices: [], hidden: [] });
      }

      const data = doc.data();

      commit("setConfig", data);

      if (data.devices !== undefined || admin) {
        dispatch("bindDevices", {
          devices: admin ? data.hidden : data.devices,
          admin,
        });
      }

      resolve();
    });
  });
}

export function unbindConfig({ commit }) {
  if (listener) {
    listener();
    listener = undefined;

    commit("setConfig", null);
  }
}
