<template>
  <navigation-bar />
  <div class="content">
    <list-controls
      v-model:filters="filters"
      v-model:sorting="sorting"
      :fields="fields"
      class="controls"
    />
    <div class="container">
      <lazy-collection
        :data="sortedDevices"
        :get-key="getID"
        :set-size="50"
        v-slot="{ entry }"
      >
        <card :class="{ card: true, hidden: isHidden(entry) }">
          <split-data :data="entry" />
          <action-link
            :page="{
              page: { path: `/device/${getID(entry)}` },
              icon: details,
              text: 'View all details',
            }"
          />
        </card>
      </lazy-collection>
    </div>
    <action-element v-if="initialized">
      <action-link
        v-if="admin"
        :page="{ icon: icon, text: 'Add new device', page: { name: 'Add' } }"
      />
      <action-link
        v-else
        :page="{ icon: icon, text: 'Link new device', page: { name: 'Link' } }"
      />
    </action-element>
  </div>
</template>

<script>
import Card from "@/components/base/Card";
import SplitData from "@/components/base/SplitData";
import ActionLink from "@/components/base/ActionLink";
import Assessment from "@/assets/assessment.svg";
import ActionElement from "@/components/base/ActionElement";
import Link from "@/assets/linkAdd.svg";
import Add from "@/assets/add.svg";
import ListControls from "@/components/ListControls";
import { markRaw } from "vue";

import queryToNumber from "@/components/base/queryToNumber";
import NavigationBar from "@/components/NavigationBar";
import LazyCollection from "@/components/LazyCollection";
import { init } from "@/store";

const fields = [
  { label: "Name", placeholder: "Device name" },
  { label: "Identifier", placeholder: "abcDEFG0123" },
  { label: "Bluetooth MAC-Address", placeholder: "XX:XX:XX:XX:XX:XX" },
  { label: "Operator", placeholder: "Name" },
  { label: "Location", placeholder: "City, ZIP code, or Country" },
  { label: "Test Count", placeholder: "Number", type: Number },
];

export default {
  name: "Devices",
  components: {
    LazyCollection,
    NavigationBar,
    ListControls,
    ActionElement,
    ActionLink,
    SplitData,
    Card,
  },
  mixins: [queryToNumber],
  async created() {
    await init;
    this.initialized = true;
  },
  computed: {
    deviceData() {
      return Object.keys(this.$store.state.devices ?? []).map((identifier) => {
        const device = this.$store.state.devices[identifier];

        return [
          { label: "Name", value: device.info?.name ?? "" },
          { label: "Identifier", value: identifier },
          { label: "Bluetooth MAC-Address", value: device.macAddress ?? "" },
          { label: "Operator", value: device.info?.operator ?? "" },
          { label: "Location", value: device.info?.location ?? "" },
          {
            label: "Test count",
            value: device.info?.deviceTestCount?.toString() ?? "",
          },
        ];
      });
    },
    filteredDevices: function () {
      let devices = this.deviceData;

      this.filters.forEach((filter) => {
        if (filter.query.length === 0) {
          return true;
        }

        let numberField = this.fields[filter.field].type === Number;
        let number = this.queryToNumber(filter.query);
        let comparator = filter.comparator ?? 0;

        if (numberField && Number.isNaN(number)) {
          return true;
        }

        devices = devices.filter((device) => {
          let value = device[filter.field].value;

          if (numberField && comparator !== 0) {
            let testCount = device[filter.field].value;

            return comparator === 1 ? testCount < number : number < testCount;
          } else {
            return value
              .toString()
              .toLocaleLowerCase()
              .includes(filter.query.toLocaleLowerCase());
          }
        });
      });

      return devices;
    },
    sortedDevices() {
      return this.filteredDevices.slice().sort((a, b) => {
        a = a[this.sorting.field].value;
        b = b[this.sorting.field].value;

        let result;

        if (fields[this.sorting.field].type !== Number) {
          result = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
        } else {
          result = a - b;
        }

        return this.sorting.ascending ? result : -1 * result;
      });
    },
    admin() {
      return this.$store.state.isAdmin;
    },
    icon() {
      return markRaw(this.admin ? Add : Link);
    },
  },
  methods: {
    getID(device) {
      return device[1].value;
    },
    isHidden(device) {
      return this.$store.state.devices[this.getID(device)].hidden;
    },
  },
  data() {
    return {
      details: markRaw(Assessment),
      initialized: false,
      fields,
      filters: [],
      sorting: {
        ascending: true,
        field: 0,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: var(--content-padding);
  padding-bottom: 0;
  width: 100%;

  > :last-child {
    position: fixed;

    bottom: var(--content-padding);
    right: calc(2 * var(--content-padding));
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 70ch);

  grid-gap: calc(2 * var(--content-padding));

  height: fit-content;
  width: calc(100% - 2 * var(--content-padding));

  justify-content: center;
  padding: var(--content-padding);
  padding-bottom: calc(10 * var(--content-padding));

  .card {
    max-width: calc(100% - 2 * var(--content-padding));
    margin: 0;
    height: fit-content;

    padding: var(--content-padding);
    padding-left: calc(2 * var(--content-padding));
    padding-top: calc(var(--content-padding) * 1.5);

    overflow-x: auto;

    & > table {
      padding-right: var(--content-padding);
    }
  }

  a {
    padding: 0 var(--content-padding) calc(var(--content-padding) / 2);
  }

  @media (max-width: calc(100ch)) {
    grid-template-columns: calc(100% - var(--content-padding));

    a {
      padding-top: var(--content-padding);
    }
  }
}

.controls {
  position: sticky;
  top: var(--content-padding);
  z-index: 1;
}

.hidden {
  transition: opacity 300ms ease-in-out;

  &:not(:hover):not(:focus-within):not(:focus) {
    opacity: 0.25;
  }
}
</style>
