<template>
  <router-view />
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss">
:root {
  --content-padding: 0.9rem;
}

* {
  margin: 0;
}

html,
body,
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  display: flex;

  height: 100vh;
  width: 100%;

  background: hsl(0, 0%, 97%);
}

button {
  cursor: pointer;

  border: none;
  outline: none;
  background: none;
}

:root {
  --dark-blue: #2f4160;
  --light-blue: #3773d7;
  --red: hsl(0, 100%, 42%);
}

a {
  color: var(--dark-blue);
  font-weight: 300;
  text-decoration: none;

  &.router-link-active {
    color: var(--light-blue);

    svg {
      fill: var(--light-blue);
    }
  }
}

svg {
  fill: var(--dark-blue);
}

th,
h6 {
  font-family: monospace;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 0.15ch;
}

#nav + .content {
  position: relative;

  padding-top: var(--content-padding);
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);

  overflow-y: auto;
}

.placeholder,
*::placeholder {
  font-weight: 300;
  font-style: italic;
  color: hsl(0, 0%, 37%);
}
</style>
