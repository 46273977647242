<template>
  <navigation-bar />
  <div class="content">
    <list-controls
      :fields="fields"
      v-model:sorting="sorting"
      v-model:filters="filters"
      class="controls"
    />
    <div class="container">
      <lazy-collection
        :data="sortedData"
        :get-key="getSampleKey"
        :set-size="30"
        v-slot="{ entry }"
      > 
        <card
          class="card"
          v-observe-visibility="{
            callback: () => fetchImages(entry),
            once: true,
          }"
        >
          <div>
            <div>
              <div class="overview">
                <div>
                  <h6>Sample ID</h6>
                  <h1>{{ entry.sampleID }}</h1>
                </div>
                <div>
                  <h6><i>E. coli</i></h6>
                  <h1>{{ entry.eColiform }}</h1>
                </div>
                <div>
                  <h6>Other Coliform</h6>
                  <h1>{{ entry.otherColiform }}</h1>
                </div>
              </div>
              <split-data
                :data="entry.data"
                class="details"
                label-width="17ch"
              />
            </div>
            <div class="image">
              <p :style="entry.flag.style">
                {{ entry.flag.text ?? "&#8203;" }}
              </p>
              <image-switcher :images="entry.images" />
            </div>
          </div>
          <div>
            <action-link
              v-if="entry.images?.raw"
              :page="{
                href: getCountingLink(entry),
                icon: search,
                text: 'Count colonies',
              }"
            />
            
            <button
              @click="
                getDownloadLink(entry)?.url !== null
                  ? download([entry], false)
                  : undefined
              "
            >
              <download
                :class="{ disabled: getDownloadLink(entry)?.url === false }"
              />
              <!--suppress HtmlUnknownTag -->
              <div v-if="getDownloadLink(entry)?.url === null">
                Generating download…
              </div>
              <!--suppress HtmlUnknownTag -->
              <div
                v-else-if="getDownloadLink(entry)?.url === false"
                class="disabled"
              >
                Could not generate download
              </div>
              <!--suppress HtmlUnknownTag -->
              <div v-else>Download result</div>
            </button>
            <button class="btn" @click="editLocation(entry)">
              <edit/> <!-- Replace 'edit-icon' with the appropriate SVG or icon component for editing -->
        <div v-if="this.isEditing">
            Save Location
        </div>
        <div v-else>Edit Location</div>
    </button>
  </div>
          
        </card>
      </lazy-collection>
    </div>
    <div class="download-menu">
      <action-element class="element">
        <button @click="createCSV(sortedData)">
          <download />
          <!--suppress HtmlUnknownTag -->
          <div v-if="fetching">Fetching image links…</div>
          <!--suppress HtmlUnknownTag -->
          <div v-else>Only results</div>
        </button>
      </action-element>
      <action-element class="element">
        <button
          @click="
            downloadLink !== null && downloadLink !== false
              ? download(filteredData, true)
              : undefined
          "
        >
          <archive :class="{ disabled: downloadLink === false }" />
          <!--suppress HtmlUnknownTag -->
          <div v-if="downloadLink === null">Generating download…</div>
          <!--suppress HtmlUnknownTag -->
          <div v-else-if="downloadLink === false" class="disabled">
            Could not generate download
          </div>
          <!--suppress HtmlUnknownTag -->
          <div v-else>Results and images</div>
        </button>
      </action-element>
      <action-element class="element">
        <button>
          <download />
          <!--suppress HtmlUnknownTag -->
          <div>Download all shown result</div>
        </button>
      </action-element>
    </div>
  </div>
</template>

<script>
import Card from "@/components/base/Card";
import SplitData from "@/components/base/SplitData";
import Download from "@/assets/download.svg";
import Archive from "@/assets/archive.svg";
import Search from "@/assets/search.svg";
import Edit from "@/assets/edit.svg";
import ActionElement from "@/components/base/ActionElement";
import ListControls from "@/components/ListControls";
import queryToNumber from "@/components/base/queryToNumber";
import NavigationBar from "@/components/NavigationBar";
import { debug } from "@/main";
import LazyCollection from "@/components/LazyCollection";
import ImageSwitcher from "@/components/ImageSwitcher";
import ActionLink from "@/components/base/ActionLink";
import { markRaw } from "vue";
import { saveAs } from "file-saver";
import firebase from "firebase/compat/app";
import { debounce } from "vue-debounce";


const flags = {
  overgrown: { color: "f44336", priority: 3 },
  new: { color: "757575", priority: 0 },
  anomalous: { color: "9e6400", priority: 2 },
  unsure: { color: "9e6400", priority: 1 },
};

function incubationTimeInMinutes(incubationTime) {
  incubationTime = incubationTime.split(":");
  incubationTime[1] = incubationTime[1]?.split(" ")[0] ?? 0;

  return Number(incubationTime[0]) * 60 + Number(incubationTime[1]);
}

const fields = [
  {
    label: "Sample ID",
    placeholder: "1234",
    type: Number,
    optionLabels: ["contains", "lower than", "higher than"],
  },
  { label: "E. coli", placeholder: "1234", type: Number },
  { label: "Other Coliform", placeholder: "1234", type: Number },
  { label: "Device", placeholder: "abcDEFG0123" },
  { label: "Location", placeholder: "City, ZIP code, or Country" },
  {
    label: "Time of submission",
    placeholder: "DD/MM/YYYY HH:mm:SS",
    type: Date,
  },
  {
    label: "Incubation time",
    placeholder: "HH:mm",
    type: Number,
    validator: (value) => Number.isNaN(incubationTimeInMinutes(value ?? "")),
  },
  { label: "Comment", placeholder: "Comment text" },
];

export default {
  name: "History",
  components: {
    ActionLink,
    ImageSwitcher,
    LazyCollection,
    NavigationBar,
    ListControls,
    ActionElement,
    Download,
    SplitData,
    Card,
    Archive,
    Edit,
  },
  mixins: [queryToNumber],
  computed: {
    data() {
      if (debug) console.time("getting data");

      let samples = [];
      Object.keys(this.$store.state.devices).forEach((identifier) => {
        const device = this.$store.state.devices[identifier];

        return (samples = samples.concat(
          Object.values(device.samples ?? {}).map((sample) => {
            sample.device = identifier;
            return sample;
          }),
        ));
      });

      samples = samples.map((data) => {
        let sampleID = data.sampleID?.toString() ?? "";

        while (sampleID.length < 4) {
          sampleID = "0" + sampleID;
        }

        let details = [
          { label: "Device", value: data.device ?? "" },
          { label: "Location", value: data.location ?? "", editable: this.isEditing },
          {
            label: "Time of submission",
            value: data.submissionTime?.toLocaleString() ?? "",
          },
          { 
    label: "Coordinates", 
    value: data.coordinates ? `${data.coordinates.latitude}, ${data.coordinates.longitude}` : "", editable: this.isEditing
  },
        ];

        const incubationEntry = {
          label: "Incubation time",
        };

        if (typeof data.incubationTime === "number") {
          const hours = Math.floor(data.incubationTime / 60);
          let minutes = (data.incubationTime - hours * 60)?.toString() ?? "";

          if (minutes.length === 1) {
            minutes = "0" + minutes;
          }

          incubationEntry.value = `${hours}:${minutes} h`;
        } else {
          incubationEntry.value = data.incubationTime ?? "";
        }

        details.push(incubationEntry);

        if ((data.comment?.length ?? 0) !== 0) {
          details.push({ label: "Comment", value: data.comment });
        }

        // region find flag with highest priority
        if (/.+\(.+\).+\(.+\)/.test(data.resultFlag ?? "")) {
          data.resultFlag = data.resultFlag
            .split(/\([^)]+\)/)
            .map((flag) => {
              flag = flag.trim().toLowerCase();
              return flag === "too_many" ? "overgrown" : flag;
            })
            .reduce((previous, current) => {
              let previousPriority = flags[previous]?.priority ?? -1;
              let currentPriority = flags[current]?.priority ?? -1;

              return previousPriority < currentPriority ? current : previous;
            });
        }
        // endregion find flag with highest priority

        const flagText = data.resultFlag !== "normal" ? data.resultFlag : null;
        const flagColor = "#" + (flags[data.resultFlag]?.color ?? "000");

        const images = {
          preview: data.images?.preview ?? null,
          raw: (data.images?.raw ?? "None").replaceAll("%253A", "%3A"),
        };

        return {
          sampleID: sampleID,
          eColiform: data.eColiform,
          otherColiform: data.otherColiform,
          images: images,
          flag: {
            text: flagText,
            style: {
              "pointer-events": data.resultFlag === null ? "none" : "all",
              color: flagColor,
            },
          },
          data: details,
        };
      });

      if (debug) console.timeEnd("getting data");

      return samples;
    },
    filteredData() {
      if (debug) console.time("filtering data");

      let data = this.data.slice();

      this.filters.forEach((filter) => {
        let number = this.queryToNumber(filter.query);
        let timestamp = Date.parse(filter.query);

        if (filter.query.length === 0) {
          return true;
        }

        data = data.filter((data) => {
          let [result, type] = this.getResultValue(data, filter.field);

          // noinspection JSIncompatibleTypesComparison,JSUnresolvedVariable
          if (type === Number && (filter.comparator ?? 0) !== 0) {
            if (filter.field === 6) {
              number = incubationTimeInMinutes(filter.query);
            } else if (filter.field === 5) {
              if (Number.isNaN(timestamp)) {
                return true;
              }

              number = new Date(timestamp);
            } else {
              result = Number(result);
            }

            if (Number.isNaN(number)) {
              return true;
            }

            // noinspection JSUnresolvedVariable
            return filter.comparator === 1 ? result < number : number < result;
          } else {
            if (filter.field > 2 && filter.field !== fields.length - 1) {
              result = data.data[filter.field - 3].value;
            }

            return result
              .toString()
              .toLocaleLowerCase()
              .includes(filter.query.toLocaleLowerCase());
          }
        });
      });

      if (debug) console.timeEnd("filtering data");

      return data;
    },
    sortedData() {
      if (debug) console.time("sorting data");

      const sortedData = this.filteredData.slice().sort((first, b) => {
        let [a, type] = this.getResultValue(first, this.sorting.field);
        b = this.getResultValue(b, this.sorting.field)[0];

        // noinspection JSIncompatibleTypesComparison
        if (type === Number) {
          a = Number(a);
          b = Number(b);

          return this.sorting.ascending ? a - b : b - a;
        } else {
          let result = a
            .toLocaleLowerCase()
            .localeCompare(b.toLocaleLowerCase());

          return this.sorting.ascending ? result : -1 * result;
        }
      });

      if (debug) console.timeEnd("sorting data");

      return sortedData;
    },
  },
  methods: {
    editLocation(sample) {
  //    (this.data);
      if (this.isEditing) {
            this.saveLocationData(sample);
        }
        this.isEditing = !this.isEditing;
          },
    saveLocationData: debounce(function(sample) {
      console.log("Sample info:")
      console.log(sample);

      this.currentSample = sample;
      const state = this.$store.state;
    const identifier = sample.data[0].value;
    const macAddress = state.devices[identifier].macAddress;

      let locationValue,coordinatesValue;
    for (let item of sample.data) {
      if (item.label === "Location") {
        locationValue = item.value;
      }
       if (item.label === "Coordinates") {
        coordinatesValue = item.value;
      }
    }
    if (locationValue !== undefined) {

      this.$store.commit('updateSampleLocation', {
  deviceIdentifier: identifier,  // or however you get the device identifier
  sampleID: sample.sampleID,        // or however you get the sample ID
  newLocation: locationValue,           // the new location value
  newCoordinates: coordinatesValue,
  macAddress: macAddress
});
      console.log(macAddress);
      console.log(identifier);
    }
    
    }, 500),
    getResultValue(result, field) {
      let type = String;

      if (field < 3) {
        type = Number;
      } else if (field >= 3) {
        type = this.fields[field].type ?? String;
      }

      if (field < 3) {
        switch (field) {
          case 0:
            result = result.sampleID;
            type = Number;
            break;

          case 1:
            result = result.eColiform;
            break;

          case 2:
            result = result.otherColiform;
            break;
        }
      } else {
        result = result.data[field - 3]?.value ?? "";
      }

      if (field === 5) {
        result = new Date(result).getTime();
        type = Number;
      } else if (field === 6) {
        result = incubationTimeInMinutes(result);

        type = Number;
      }

      return [result, type];
    },
    normaliseSample(sample) {
      const state = this.$store.state;

      const identifier = sample.data[0].value;
      const macAddress = state.devices[identifier].macAddress;

      return {
        sampleID: sample.sampleID,
        eColiformColonies: sample.eColiform,
        otherColiformColonies: sample.otherColiform,
        flag: sample.flag.text?.trim() ?? "normal",
        deviceIdentifier: identifier,
        deviceMacAddress: macAddress,
        deviceOperator: state.devices[identifier].info.operator?.trim() ?? "",
        deviceLocation: state.devices[identifier].info.location?.trim() ?? "",
        sampleLocation: sample.data[1]?.value?.trim() ?? "",
        submissionTime: new Date(sample.data[2]?.value?.trim()).toISOString(),
        incubationTime: (sample.data[3]?.value?.split(" ") ?? [""])[0],
        comment: sample.data[4]?.value?.trim() ?? "",
      };
    },
    async createCSV(samples) {
      this.fetching = true;

      let content = '"Sample ID",';
      content += '"E. coli colonies",';
      content += '"Other coliform colonies",';
      content += '"Flag",';
      content += '"Device identifier",';
      content += '"Device MAC Address",';
      content += '"Device operator",';
      content += '"Device location",';
      content += '"Sample location",';
      content += '"Time of submission",';
      content += '"Incubation time",';
      content += '"Comment",';
      content += '"Preview image URL",';
      content += '"Raw image URL"\n';

      await Promise.all(samples.map((entry) => this.fetchImages(entry)));

      samples.forEach((sample) => {
        const normalised = this.normaliseSample(sample);

        content += `${normalised.sampleID},`;
        content += `"${normalised.eColiformColonies}",`;
        content += `"${normalised.otherColiformColonies}",`;
        content += `"${normalised.flag}",`;
        content += `"${normalised.deviceIdentifier}",`;
        content += `"${normalised.deviceMacAddress}",`;
        content += `"${normalised.deviceOperator}",`;
        content += `"${normalised.deviceLocation}",`;
        content += `"${normalised.sampleLocation}",`;
        content += `"${normalised.submissionTime}",`;
        content += `"${normalised.incubationTime}",`;
        content += `"${normalised.comment}",`;

        content += `${
          typeof sample.images.preview === "string"
            ? `"${sample.images.preview}"`
            : '""'
        },`;
        content += `${
          typeof sample.images.raw === "string"
            ? `"${sample.images.raw}"`
            : '""'
        }`;
        content += "\n";
      });

      this.fetching = false;

      saveAs(
        new Blob([content], { type: "text/csv;charset=utf-8" }),
        "samples.csv",
      );
    },
    async download(samples, all) {
      console.count("Generating");

      if (
        (!all && !this.validDownloadLink(samples)) ||
        (all && !this.downloadLink)
      ) {
        console.count("Generating");

        samples = samples.map(this.normaliseSample);

        const downloadSamples = firebase
          .functions()
          .httpsCallable("downloadSamples");

        if (all) {
          this.downloadLink = null;
          try {
            this.downloadLink = (await downloadSamples(samples)).data;
          } catch (e) {
            this.downloadLink = false;
          }
        } else {
          this.$store.commit("setSampleDownload", {
            deviceID: samples[0].deviceIdentifier,
            sampleID: samples[0].sampleID,
            url: null,
          });

          try {
            this.$store.commit("setSampleDownload", {
              deviceID: samples[0].deviceIdentifier,
              sampleID: samples[0].sampleID,
              url: (await downloadSamples(samples)).data,
            });
          } catch (e) {
            this.$store.commit("setSampleDownload", {
              deviceID: samples[0].deviceIdentifier,
              sampleID: samples[0].sampleID,
              url: false,
            });
          }
        }
      }

      if (all) {
        if (this.downloadLink !== false) {
          window.location.assign(this.downloadLink);
        }
      } else {
        const url = this.getDownloadLink(samples[0]).url;
        if (url !== false) {
          window.location.assign(url);
        }
      }
    },
    getSampleKey(sample) {
      return sample.data[0].value + sample.sampleID;
    },
    fetchImages(entry) {
      const deviceID = entry.data[0].value;

      return this.$store.dispatch("getImageURLs", {
        sampleID: Number(entry.sampleID),
        deviceID,
        macAddress: this.$store.state.devices[deviceID].macAddress,
      });
    },
    getCountingLink(entry) {
      const urlPrefix =
        "https://firebasestorage.googleapis.com/v0/b/waterscope4dd2f.appspot.com/o/";

      var newUrl =
        `https://waterscope.org/colony_count/index.html?${entry.images.raw.substr(
          urlPrefix.length + 1,
        )}?id=` + entry.sampleID;
      return newUrl;
    },
    validDownloadLink(samples) {
      const link = this.getDownloadLink(samples[0]);
      return (link?.url ?? false) !== false && link.expiration > new Date();
    },
    getDownloadLink(sample) {
      const id = sample.sampleID;
      const device = sample.deviceIdentifier ?? sample.data[0].value;

      return (this.$store.state.downloads[device] ?? {})[id];
    },
  },
  watch: {
    filteredData() {
      this.downloadLink = undefined;
    },
  },
  data() {
    return {
      fetching: false,
      downloadLink: undefined,
      fields,
      filters: [],
      sorting: {
        ascending: true,
        field: 0,
      },
      search: markRaw(Search),
      isEditing: false, // New data property for edit mode
      updatedLocationValue: '',
      currentSample: null
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: var(--content-padding);
  padding-bottom: calc(6 * var(--content-padding));
  width: 100%;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 85ch);
  justify-content: center;

  @media (max-width: 105ch) {
    grid-template-columns: 1fr;
  }
}

.card:not(.element) {
  display: flex;
  flex-direction: column;

  padding: var(--content-padding);

  > :first-child {
    flex: 1;
    display: flex;

    & > :first-child {
      flex: 2;
    }

    & > :last-child {
      flex: 1;
    }
  }

  h1,
  h6 {
    text-align: left;
  }

  .overview {
    display: flex;
    justify-content: space-between;

    & > div {
      margin: var(--content-padding);
    }
  }

  .details {
    margin: var(--content-padding);
    width: calc(100% - 2 * var(--content-padding));
  }

  .image {
    min-height: 16.5rem;
  }

  .image > * {
    margin: var(--content-padding);
    width: calc(100% - 2 * var(--content-padding));

    &:first-child {
      margin-bottom: 0;
      margin-top: calc(0.8 * var(--content-padding));

      text-align: left;
      text-transform: capitalize;
      font-weight: 500;
    }

    &:last-child {
      display: grid;
      place-content: center;

      height: 80%;
      margin-top: calc(var(--content-padding) / 2);
    }
  }

  button {
    padding: 0;
    margin-top: 1px;
  }

  a + button {
    margin-left: calc(var(--content-padding) * 2);
  }

  > :last-child {
    display: flex;
    justify-content: center;

    margin-top: var(--content-padding);

    padding: var(--content-padding) var(--content-padding)
      calc(var(--content-padding) * 0.25);

    border-top: #00000088 1px dashed;

    a {
      font-weight: normal;
      margin-left: 0;
    }
  }
}

button {
  display: flex;
  width: fit-content;
  padding: 0;

  font-size: 1rem;

  div {
    margin: auto 0 0.1rem;
    color: var(--dark-blue);
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0 calc(var(--content-padding) / 2) 0 0;
  }
}

.btn {
    margin-left: 10px; // Adjust the value as needed.
}
.download-menu {
  width: 33ch;

  position: fixed;
  right: calc(var(--content-padding) * 2);
  bottom: var(--content-padding);

  > * {
    margin-left: auto;

    &:not(:last-child) {
      margin-bottom: var(--content-padding);
    }
  }

  &:not(:focus-within) > *:not(:last-child) {
    display: none;
  }
}

.disabled {
  opacity: 60%;
}

.controls {
  position: sticky;
  top: var(--content-padding);

  z-index: 2;
}
</style>
