import { storage } from "@/store/index";

export function getColonyLevel(sample) {
  if (sample.resultFlag === "new") {
    return "outstanding";
  }

  const colonyCount = sample.eColiform + sample.otherColiform;

  if (colonyCount === 0) {
    return "none";
  } else if (colonyCount < 11) {
    return "low";
  } else if (colonyCount < 51) {
    return "medium";
  } else if (colonyCount < 101) {
    return "high";
  } else {
    return "veryHigh";
  }
}

function samplePreparation(sample) {
  // remove empty spaces in location names
  sample.location = sample.location
    .split(/, */)
    .filter((part) => part.length !== 0)
    .join(", ");

  if (sample.submissionTime) {
    sample.submissionTime = convertTimestamp(sample.submissionTime);
  }

  return sample;
}

export function setSample(state, { deviceID, sampleID, value }) {
  if (state.devices[deviceID] === undefined) {
    state.devices[deviceID] = {};
  }

  const samples = state.devices[deviceID]?.samples ?? {};

  if (value !== undefined) {
    value = samplePreparation(value);

    const previous = samples[sampleID];

    // always show the latest submitted sample
    const prevTime = previous?.submissionTime?.getTime() ?? 0;
    const timeNow = value.submissionTime.getTime();

    if (prevTime <= timeNow) {
      if (previous?.images) {
        value.images = previous.images;
      }

      // region update states
      if (previous) {
        --state.stats[getColonyLevel(previous)];
      }

      ++state.stats[getColonyLevel(value)];
      // endregion update states

      samples[sampleID] = value;
    }
  } else {
    --state.stats[getColonyLevel(samples[sampleID])];
    delete samples[sampleID];
  }

  state.devices[deviceID].samples = samples;
}

export function setSampleImages(state, { deviceID, sampleID, images }) {
  let sample = state.devices[deviceID]?.samples[sampleID];

  if (sample !== undefined) {
    sample.images = images;
  }
}

export function setSampleDownload(state, { deviceID, sampleID, url }) {
  if (state.downloads[deviceID] === undefined) {
    state.downloads[deviceID] = {};
  }

  const now = new Date();

  state.downloads[deviceID][sampleID] = {
    url,
    expiration: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 2,
      now.getHours(),
      now.getMinutes(),
    ),
  };
}

export function clearStats(state) {
  state.stats = {
    none: 0,
    low: 0,
    medium: 0,
    high: 0,
    veryHigh: 0,
    outstanding: 0,
  };
}

function wrapImagePromise(directory, filename) {
  if (directory[directory.length - 1] !== "/") {
    directory += "/";
  }

  return new Promise((resolve, reject) => {
    const result = {
      directory,
      filename,
    };

    storage
      .ref(directory + filename)
      .getDownloadURL()
      .then((url) => {
        result.url = url;
        resolve(result);
      })
      .catch((error) => {
        result.error = error;
        reject(result);
      });
  });
}

async function getTimelapseImage(urls, items) {
  const mostRecent = {};

  items.forEach((file) => {
    const name = file.name.split("_");
    const type = name[name.length - 1].split(".")[0];

    if ((mostRecent[type]?.number ?? -1) < Number(name[0])) {
      mostRecent[type] = { number: Number(name[0]), ref: file };
    }
  });

  let fetcher = [];

  if (!("preview" in urls) && "result" in mostRecent) {
    fetcher.push(wrapImagePromise("", mostRecent.result.ref.fullPath));
  }

  if (!("raw" in urls) && "compressed" in mostRecent) {
    fetcher.push(wrapImagePromise("", mostRecent.compressed.ref.fullPath));
  }

  fetcher = await Promise.allSettled(fetcher);
  fetcher.forEach((result) => {
    if (result.status === "fulfilled") {
      const filePath = result.value.filename;

      if (mostRecent?.result?.ref?.fullPath === filePath) {
        urls.preview = result.value.url;
      } else if (mostRecent?.compressed?.ref?.fullPath === filePath) {
        urls.raw = result.value.url;
      }
    }
  });

  return urls;
}

export async function getImageURLs(
  { commit, state },
  { deviceID, sampleID, macAddress },
) {
  const images = state.devices[deviceID]?.samples[sampleID]?.images;

  if (images) {
    return images;
  }

  const mac = macAddress;
  let directory = `/${mac}/${sampleID}`;

  let urls = [];

  urls.push(wrapImagePromise(directory, "preview.jpg"));
  urls.push(wrapImagePromise(directory, "raw.jpg"));

  const results = await Promise.allSettled(urls);

  urls = {};

  results
    .filter((results) => results.status === "fulfilled")
    .forEach((results) => {
      const value = results.value;
      return (urls[value.filename.split(".")[0]] = value.url);
    });

  if (Object.values(urls).length < 2) {
    directory = `${mac}/timelapse_data/${sampleID}`;

    const result = await storage.ref(directory).listAll();
    commit("setSampleImages", {
      deviceID: deviceID,
      sampleID,
      images: await getTimelapseImage(urls, result.items),
    });
  }

  commit("setSampleImages", {
    deviceID: deviceID,
    sampleID,
    images: urls,
  });

  return urls;
}

function snapshotHandler({ commit, snapshot, identifier, idGetter }) {
  snapshot.docChanges().forEach((change) => {
    if (change.type !== "removed") {
      commit("setSample", {
        deviceID: identifier.identifier,
        sampleID: idGetter(change.doc),
        value: { ...change.doc.data(), sampleID: idGetter(change.doc) },
      });
    } else {
      commit("setSample", {
        deviceID: identifier.identifier,
        sampleID: idGetter(change.doc),
        value: undefined,
      });
    }
  });
}

function convertTimestamp(timestamp) {
  return new Date(
    timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1000),
  );
}

const sampleListeners = {};
const iotSampleListeners = {};

export function addSampleListeners({ commit, identifier }) {
  if (!(identifier.identifier in sampleListeners)) {
    sampleListeners[identifier.identifier] = identifier.info
      .collection("samples")
      .onSnapshot((snapshot) =>
        snapshotHandler({
          commit,
          snapshot,
          identifier,
          idGetter: (doc) => doc.id,
        }),
      );
  }

  if (!(identifier.identifier in iotSampleListeners)) {
    iotSampleListeners[identifier.identifier] = identifier.info
      .collection("samples_iot")
      .onSnapshot((snapshot) =>
        snapshotHandler({
          commit,
          snapshot,
          identifier,
          idGetter: (doc) => doc.data().sample_ID,
        }),
      );
  }
}

export function removeSampleListeners(identifier) {
  let sampleListener = sampleListeners[identifier];

  if (sampleListener !== undefined) {
    sampleListener();
    delete sampleListeners[identifier];
  }

  sampleListener = iotSampleListeners[identifier];
  if (sampleListener !== undefined) {
    sampleListener();
    delete iotSampleListeners[identifier];
  }
}

export function removeAllSamples(state, { deviceID }) {
  Object.keys(state.devices[deviceID].samples).forEach((sampleID) =>
    setSample(state, { deviceID, sampleID, value: undefined }),
  );
}
