<template>
  <div v-for="i in visible" :key="getKey(data[i - 1])">
    <slot :entry="data[i - 1]" />
  </div>
  <!--suppress HtmlUnknownAttribute -->
  <div v-observe-visibility="increaseVisible" />
</template>

<script>
export default {
  name: "LazyCollection",
  props: {
    data: {
      type: Array,
      required: true,
      validator(value) {
        return value !== undefined;
      },
    },
    setSize: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0 && Number.isInteger(value);
      },
    },
    getKey: {
      type: Function,
      required: true,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value, old) {
        if (value.length !== old?.length ?? 0) {
          if (this.visible > value.length) {
            this.visible = value.length;
          } else if (this.setSize < value.length) {
            this.visible = this.setSize;
          } else {
            this.visible = value.length;
          }
        }
      },
    },
  },
  methods: {
    increaseVisible(visible) {
      if (visible && this.visible !== 0 && this.data !== undefined) {
        const leftEntries = this.data.length - this.visible;

        this.visible += leftEntries < this.setSize ? leftEntries : this.setSize;
      }
    },
  },
  data() {
    return {
      visible: 0,
    };
  },
};
</script>

<style scoped></style>
