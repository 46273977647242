<template>
  <card>
    <h2>Test result locations</h2>
    <div class="map">
      <l-map :zoom="1" :center="center">
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          :attribution="attribution"
        />
        <l-marker
          v-for="sample in samples"
          :key="sample.id"
          :lat-lng="sample.coordinates && sample.coordinates.latitude && sample.coordinates.longitude ? [sample.coordinates.latitude, sample.coordinates.longitude] : [0, 0]"
        />
      </l-map>
    </div>
    <p>Number of markers: {{ markersCount }}</p>  <!-- Add this line -->
  </card>
</template>

<script>
import Card from "@/components/base/Card";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

const attribution =
  "&copy; " +
  '<a href="http://osm.org/copyright">OpenStreetMap</a> ' +
  "contributors&ensp;";

export default {
  name: "LocationOverview",
  components: { Card, LMap, LTileLayer, LMarker },
  data() {
    return {
      center: [4.505, 34.09], // This sets the initial center to London.
      attribution,
    };
  },
  computed: {
  samples() {
    let samples = [];

    Object.values(this.$store.state.devices).forEach((device) => {
      Object.values(device?.samples ?? {}).forEach((sample) => {
        if (sample.coordinates) {
          samples.push(sample);
        }
      });
    });

    return samples;
  },
    markersCount() {
      return this.samples.length;
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  padding: calc(var(--content-padding) * 2);
  padding-bottom: var(--content-padding);
}

.map {
  height: 30rem;
  overflow: hidden;
  border-radius: 0 0 1rem 1rem;
}
</style>
